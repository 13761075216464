import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // FontAwesome icons for password toggle
import "../assets/css/addagent.css"

function AddAgent() {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null); // For image preview

    const authorid = localStorage.getItem("authorid");
    const token = localStorage.getItem("responsetoken");

    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "username") setUsername(value);
        if (name === "name") setName(value);
        if (name === "phone") setPhone(value);
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
    };

    // Handle file input and set image preview
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Create image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !name || !phone || !email || !password || !file || !authorid) {
            toast.error("All fields are required.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('username', username);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('file', file);
        formData.append('author_ID', authorid);

        debugger;
        try {
            const response = await axios.post('https://rollerfashions.adef.tech/api/B2B_Agent/Create_B2BAgent', formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            toast.success("Agent added successfully!", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            // Reset form fields
            setUsername('');
            setName('');
            setPhone('');
            setEmail('');
            setPassword('');
            setFile('');
            setImagePreview(''); // Reset image preview
        } catch (error) {
            console.error("Error adding agent:", error);
            toast.error("Failed to add agent. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        }
    };

    return (
        <Container className="mt-5">
            <div className='card addproduct-card'>
                <h3 className="coupon-title">Add Agent</h3>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <div className="password-input-wrapper">
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={password}
                                        onChange={handleInputChange}
                                        required
                                        placeholder="Enter your password"
                                    />
                                    <span
                                        className="password-toggle"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </Form.Group>

                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="file">
                                <Form.Label>Upload File</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="file"
                                    onChange={handleFileChange}
                                    required
                                />
                                {imagePreview && (
                                    <div className="image-preview mt-3">
                                        <img src={imagePreview} alt="Image Preview" width="100%" />
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type="submit" variant="primary">
                        Add Agent
                    </Button>
                </Form>
            </div>
            <ToastContainer />
        </Container>
    );
}

export default AddAgent;
