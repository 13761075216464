import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";
import '../assets/css/Adminprofile.css';

function AdminProfile() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [id, setId] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('responsetoken');
    const storedId = localStorage.getItem('authorid'); // Retrieve the admin ID

    // Set values from localStorage
    if (storedId) setId(storedId);
    if (storedToken) setToken(storedToken);

    // Fetch admin details if ID exists
    if (storedId && storedToken) {
      fetchAdminDetails(storedId, storedToken);
    }
  }, [navigate]);

  // Function to fetch admin details using the ID (responseobject)
  const fetchAdminDetails = async (adminId, token) => {
    try {
      const response = await axios.get('https://rollerfashions.adef.tech/api/Admin/Get_Admin', {
        params: { id: adminId },
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // Check if the response contains a username and set it
      if (response.data && response.data.username) {
        setUsername(response.data.username); // Set the fetched username
      } else {
        toast.error("Failed to fetch admin details.");
        console.error('Username not found in the response.');
      }
    } catch (error) {
      console.error("Error fetching admin details:", error);
      toast.error("An error occurred while fetching admin details.");
    }
  };

  const validatePassword = (password) => {
    const minLength = 6;
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setPasswordError(''); 

    const error = validatePassword(newPassword);
    if (error) {
      setPasswordError(error);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post("https://rollerfashions.adef.tech/api/Admin/Admin_Changepassword", {
        id: id,
        password: newPassword,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      setLoading(false);

      if (response.data.responseCode === 1) {
        toast.success("Password changed successfully.");
        localStorage.setItem('password', newPassword);
        localStorage.removeItem('responsetoken');
        localStorage.removeItem('authorid');
        navigate("/adminlogin");
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('responsetoken');
    localStorage.removeItem('authorid');
    navigate("/adminlogin");
  };

  return (
    <Container fluid className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow-sm border-0 rounded-lg">
            <Card.Body className="p-4">
              <h4 className="text-center mb-4 text-dark">Admin Profile</h4>
              
              {/* Username */}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group controlId="formUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        value={username || 'Loading...'}
                        readOnly
                        className="form-control-lg"
                        style={{ borderRadius: '30px' }}
                      />
                    </Form.Group>
                  </Col>
                  {/* Current Password */}
                  <Col md={12} className="mb-3">
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        readOnly
                        className="form-control-lg"
                        style={{ borderRadius: '30px' }}
                      />
                    </Form.Group>
                  </Col>
                  {/* New Password */}
                  <Col md={12} className="mb-3">
                    <Form.Group controlId="formNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="form-control-lg"
                        style={{ borderRadius: '30px' }}
                      />
                      {passwordError && <small className="text-danger">{passwordError}</small>}
                    </Form.Group>
                  </Col>

                  {/* Update Password Button */}
                  <Col md={12}>
                    <Button 
                      variant="primary" 
                      type="submit" 
                      disabled={loading} 
                      className="mt-4 w-100 rounded-pill"
                      style={{ padding: '12px 20px' }}
                    >
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" /> Changing Password...
                        </>
                      ) : (
                        "Update Password"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>

              {/* Logout Button */}
              <Button
                variant="danger"
                className="mt-4 w-100 rounded-pill"
                style={{ padding: '12px 20px' }}
                onClick={handleLogout}
              >
                Logout
              </Button>

              <ToastContainer />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminProfile;
