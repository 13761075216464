import React, { useState, useEffect } from 'react';
import { Table, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/fittypelist.css";
function FitTypeList() {
    const [fitTypes, setFitTypes] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem("authorid");

    useEffect(() => {
        fetchFitTypes();
    }, []);

    const fetchFitTypes = async () => {
        try {
            const response = await axios.get("https://rollerfashions.adef.tech/api/Fit_type/List_fittype", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
            });
            setFitTypes(response.data);
        } catch (error) {
            console.error("Error fetching fit types:", error);
            toast.error("An error occurred while fetching fit types. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (fitTypeId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `https://rollerfashions.adef.tech/api/Fit_type/Delete_fittype`;
                    const obj = {
                        id: fitTypeId,
                        author_ID: authorId
                    };

                    const response = await axios.post(url, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    toast.success(response.data.responsemessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    fetchFitTypes();
                    Swal.fire(
                        'Deleted!',
                        'The fit type has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting fit type:", error);
                    toast.error("An error occurred while deleting the fit type. Please try again.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    };

    return (

        <div className='container-fluid content-wrapper'>


            <div className="table-responsive mt-5">

                <Card.Body>
                    <h2 className='coupon-title'>Fit Type List</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Fit Name</th>
                                <th>Description</th>
                                <th>Flexibility</th>
                                <th>Body Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fitTypes && fitTypes.map(fitType => (
                                <tr key={fitType.id}>
                                    <td>{fitType.fitname}</td>
                                    <td className='type'>{fitType.description}</td>
                                    <td>{fitType.flexibility}</td>
                                    <td>{fitType.bodytype}</td>
                                    <td>
                                        <Link to={`/editfittype/${fitType.id}`}>
                                            <Button variant="link">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                        <Button variant="link" className="ms-2" onClick={() => handleDelete(fitType.id)}>
                                            <FaTrashAlt />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>


                <ToastContainer />
            </div>
        </div>
    );
}

export default FitTypeList;
