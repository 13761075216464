import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Dashboard from "./Components/Dashboard";
import Orders from "./Components/Orders";
import Settings from "./Components/Settings";
import Addproduct from "./Components/Addproduct";
import AdminProfile from "./Components/Adminprofile";
import Reports from "./Components/Reports";
import ProductList from "./Components/Productlist";
import Admin_Login from "./Components/Adminlogin";
import AddChildProduct from "./Components/Addchildproduct";
import ChildProductList from "./Components/Childproductlist";
import Category from "./Components/Category";
import Subcategory from "./Components/Subcategory";
import Colormanager from "./Components/Colormanager";
import Addcoupon from "./Components/Addcoupon";
import EditCoupon from "./Components/Editcoupon";
import ListCoupons from "./Components/Listcoupons";
import Addcombo from "./Components/Addcombo";
import Combolist from "./Components/Combolist";
import AddBanner from "./Components/Addbanner";
import BannerList from "./Components/Bannerlist";
import EditCombo from "./Components/Editcombo";
import EditProduct from "./Components/Editproduct";
import AddCustomBanner from "./Components/Addcustombanner";
import CustomBannerList from "./Components/Custombannerlist";
import AddFabricType from "./Components/Addfabrictype";
import FabricTypeList from "./Components/Fabrictypelist";
import EditFabricType from "./Components/Editfabrictype";
import Addbrand from "./Components/Addbrand";
import Brandlist from "./Components/Brandlist";
import EditB2BBrand from "./Components/Editbrand";
import AddB2BSets from "./Components/Addb2bsets";
import SetsList from "./Components/Setslist";
import AddAgent from "./Components/Addagent";
import ListAgent from "./Components/Listagent";
import EditB2BSets from "./Components/Editsets";
import EditAgents from "./Components/Editagents";
import AddB2BRetailer from "./Components/Addb2bretailer";
import RetailerList from "./Components/Retailerlist";
import EditRetailer from "./Components/Editretailer";
import AddFitType from "./Components/Addfittype";
import FitTypeList from "./Components/Fittypelist";
import EditFitType from "./Components/Editfittype";
import AddHSN from "./Components/Addhsn";
import EditHSN from "./Components/Edithsn";
import HSNList from "./Components/Hsnlist";
import AddPattern from "./Components/Addpattern";
import EditPattern from "./Components/Editpattern";
import PatternList from "./Components/Patternlist";
import AddOccasion from "./Components/Addoccasion";
import EditOccasion from "./Components/Editoccasion";
import OccasionList from "./Components/Occasionlist";
import AddCollar from "./Components/Addcollar";
import CollarList from "./Components/Collarlist";
const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

const MainApp = () => {
  const location = useLocation();

  return (
    <div className="app">
      {/* Conditionally render Sidebar based on the current route */}
      {location.pathname !== '/' && location.pathname !== '/adminlogin' && <Sidebar />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Admin_Login />} />
          <Route path="/adminlogin" element={<Admin_Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addproduct" element={<Addproduct />} />
          <Route path="/productlist" element={<ProductList />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/adminprofile" element={<AdminProfile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/addchildproduct" element={<AddChildProduct />} />
          <Route path="/childproductlist" element={<ChildProductList />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/category" element={<Category />} />
          <Route path="/subcategory" element={<Subcategory />} />
          <Route path="/addbrand" element={<Addbrand />} />
          <Route path="/color" element={<Colormanager />} />
          <Route path="/addcoupons" element={<Addcoupon />} />
          <Route path="/editcoupons" element={<EditCoupon />} />
          <Route path="/listcoupons" element={<ListCoupons />} />
          <Route path="/editcoupons/:couponId" element={<EditCoupon />} />
          <Route path="/addcombo" element={<Addcombo />} />
          <Route path="/combolist" element={<Combolist />} />
          <Route path="/addbanner" element={<AddBanner />} />
          <Route path="/bannerlist" element={<BannerList />} />
          <Route path="/editcombo/:comboId" element={<EditCombo />} />
          <Route path="/subcategory/:categoryId" element={<Subcategory />} />
          <Route path="/editproduct/:id" element={<EditProduct />} />
          <Route path="/custombanner" element={<AddCustomBanner />} />
          <Route path="/custombannerlist" element={<CustomBannerList />} />
          <Route path="/addfabrictype" element={<AddFabricType />} />
          <Route path="/fabrictypelist" element={<FabricTypeList />} />
          <Route path="/editfabrictype/:fabricId" element={<EditFabricType />} />
          <Route path="/brandlist" element={<Brandlist />} />
          <Route path="/editbrand/:brandId" element={<EditB2BBrand />} />
          <Route path="/addsets" element={<AddB2BSets />} />
          <Route path="/setslist" element={<SetsList />} />
          <Route path="/addagent" element={<AddAgent />} />
          <Route path="/listagent" element={<ListAgent />} />
          <Route path="/editsets/:setID" element={<EditB2BSets />} />
          <Route path="/editagent/:agentID" element={<EditAgents />} />
          <Route path="/addretailer" element={<AddB2BRetailer />} />
          <Route path="/retailerlist" element={<RetailerList />} />
          <Route path="/editretailer/:id" element={<EditRetailer />} />
          <Route path="/addfittype" element={<AddFitType />} />
          <Route path="/fittypelist" element={<FitTypeList />} />
          <Route path="/editfittype/:fitId" element={<EditFitType />} />
          <Route path="/addhsn" element={<AddHSN />} />
          <Route path="/hsnlist" element={<HSNList />} />
          <Route path="/edithsn/:id" element={<EditHSN />} />
          <Route path="/addpattern" element={<AddPattern />} />
          <Route path="/patternlist" element={<PatternList />} />
          <Route path="/editpattern/:id" element={<EditPattern />} />
          <Route path="/addoccasion" element={<AddOccasion />} />
          <Route path="/occasionlist" element={<OccasionList />} />
          <Route path="/editoccasion/:id" element={<EditOccasion />} />
          <Route path="/addcollar" element={<AddCollar />} />
          <Route path="/collarlist" element={<CollarList />} />
          <Route path="/editcollar/:id" element={<EditOccasion />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
