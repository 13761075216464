import React from 'react';

const Settings = () => {
  return (
    <div className="page">
      <h2>Page 2</h2>
      <p>This is the content of Page 2.</p>
    </div>
  );
};

export default Settings;
