import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import "../assets/css/addb2bsets.css";

function AddB2BSets() {
    const [name, setName] = useState('');
    const [productID, setProductID] = useState('');
    const [setsQuantity, setSetsQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [displayImage, setDisplayImage] = useState(null);
    const [sampleImages, setSampleImages] = useState(new Array(4).fill(null));
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [productOptions, setProductOptions] = useState([]);

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const url = 'https://rollerfashions.adef.tech/api/Product/List_Product';
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setProductOptions(response.data); // Store the product options for the dropdown
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            toast.error("Failed to fetch products", { position: "top-right", autoClose: 3000 });
        }
    };

    const handleInputChange = (e, setter) => {
        setter(e.target.value);
    };

    const handleFileChange = (e, setter) => {
        setter(e.target.files);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 102400) { // Check if file size <= 100KB
            setDisplayImage(file);
        } else {
            toast.error("Display image size should be less than or equal to 100KB");
        }
    };
    const handleSampleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file && file.size <= 102400) { // Check if file size <= 100KB
            const updatedSampleImages = [...sampleImages];
            updatedSampleImages[index] = file;
            setSampleImages(updatedSampleImages);
        } else {
            toast.error("Image size should be less than or equal to 100KB");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        setLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('product_ID', productID);
        formData.append('sets_Quantity', setsQuantity);
        formData.append('total_Price', totalPrice);
        formData.append('selling_price', sellingPrice);
        formData.append('discount', discount);
        formData.append('author_ID', authorid);

        if (displayImage) {
            formData.append('display_image', displayImage);
        }

        sampleImages.forEach((sampleImage, index) => {
            if (sampleImage) {
                formData.append(`sample_image${index + 1}`, sampleImage);
            }
        });

        if (video) {
            formData.append('video', video);
        }

        formData.forEach((value, key) => {
            console.log(key, value);
        });

        debugger;
        try {
            const response = await axios.post(
                'https://rollerfashions.adef.tech/api/B2B_Sets/Create_B2BSets',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.status === 1) {
                // Clear form fields after successful submission
                setName('');
                setProductID('');
                setSetsQuantity(0);
                setTotalPrice(0);
                setSellingPrice(0);
                setDiscount(0);
                setDisplayImage(null);
                setSampleImages(new Array(4).fill(null));
                setVideo(null);

                toast.success("B2B Set added successfully!", { position: "top-right", autoClose: 3000 });
            }
        } catch (error) {
            toast.error("Failed to add B2B Set. Please try again.", { position: "top-right", autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='container-fluid addproduct addproduct-container'>
            <div className='card addproduct-card'>
                <h2 className='coupon-title'>Add B2B Sets</h2>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Label>Set Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={name}
                                    onChange={(e) => handleInputChange(e, setName)}
                                    required
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label>Product</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={productID}
                                    onChange={(e) => setProductID(e.target.value)}
                                    required
                                >
                                    <option value="">Select Product</option>
                                    {productOptions.map((product) => (
                                        <option key={product.id} value={product.id}>
                                            {product.productID} - {product.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Label>Sets Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={setsQuantity}
                                    onChange={(e) => handleInputChange(e, setSetsQuantity)}
                                    required
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label>Total Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={totalPrice}
                                    onChange={(e) => handleInputChange(e, setTotalPrice)}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Label>Selling Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={sellingPrice}
                                    onChange={(e) => handleInputChange(e, setSellingPrice)}
                                    required
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label>Discount</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={discount}
                                    onChange={(e) => handleInputChange(e, setDiscount)}
                                    required
                                />
                            </Col>
                        </Row>

                        {/* Display Image */}
                        <Col md={12} className="mb-3">
                            <Form.Label>Display Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageUpload}
                                required
                                accept="image/jpeg, image/png"
                            />
                            {displayImage && (
                                <Card className="mt-3">
                                    <Card.Img variant="top" src={URL.createObjectURL(displayImage)} />
                                    <Card.Body>
                                        <Card.Title>Display Image</Card.Title>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>

                        {/* Sample Images */}
                        <Col md={12} className="mb-3">
                            <Row>
                                {sampleImages.map((sampleImage, index) => (
                                    <Col md={3} key={index}>
                                        <Form.Group className="mb-3" controlId={`sampleImage${index + 1}`}>
                                            <Form.Label>Upload Sample Image {index + 1}</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => handleSampleImageChange(e, index)}
                                                accept="image/jpeg, image/png"
                                            />
                                            {sampleImage && (
                                                <Card className="mt-3">
                                                    <Card.Img variant="top" src={URL.createObjectURL(sampleImage)} />
                                                    <Card.Body>
                                                        <Card.Title>Sample Image {index + 1}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                        {/* Video */}
                        <Col md={6} className="mb-3">
                            <Form.Label>Video</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setVideo(e.target.files[0])}
                                accept="video/*"
                            />
                        </Col>

                        {/* Submit Button */}
                        <Col md={12} className="mb-3">
                            <Button type="submit" variant="primary" disabled={loading} onClick={handleSubmit}>
                                {loading ? "Saving..." : "Save Set"}
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddB2BSets;
