import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const EditOccasion = () => {
    const { id } = useParams(); // Get the Occasion ID from the URL
    const navigate = useNavigate();
    const [occasionType, setOccasionType] = useState('');
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem('authorid');

    useEffect(() => {
        const fetchOccasionDetails = async () => {
            try {
                const response = await axios.get(`https://rollerfashions.adef.tech/api/Occasion/Get_Occasion?Occasion_ID=${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.data) {
                    setOccasionType(response.data.occasion_type); // Assuming occasion_type exists in the response
                }
            } catch (error) {
                console.error('Error fetching occasion details:', error);
                toast.error('Failed to fetch occasion details');
            }
        };

        fetchOccasionDetails();
    }, [id, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const occasionData = {
            id: id,
            occasion_type: occasionType,
            author_ID: authorId,
        };

        try {
            const response = await axios.post(`https://rollerfashions.adef.tech/api/Occasion/Edit_Occasion`, occasionData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.responseCode === 1) {
                toast.success('Occasion updated successfully!');
                navigate('/AdminDashboard/occasionlist'); // Redirect to the occasion list after successful update
            } else {
                toast.error('Failed to update occasion');
            }
        } catch (error) {
            console.error('Error updating occasion:', error);
            toast.error('An error occurred while updating the occasion');
        }
    };

    return (
        <Container fluid className="mt-5">
            <Row>
                <Col md={0}>
                    
                </Col>
                <Col md={12}>
                    <h2 className='coupon-title'>Edit Occasion</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formOccasionType">
                            <Form.Label>Occasion Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter occasion type"
                                value={occasionType}
                                onChange={(e) => setOccasionType(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Update Occasion
                        </Button>
                    </Form>
                </Col>
            </Row>

            <ToastContainer />
        </Container>
    );
};

export default EditOccasion;
