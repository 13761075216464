import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, BarChart, Bar, ResponsiveContainer } from "recharts";
import { FaDollarSign, FaShoppingCart, FaUsers, FaTruck, FaBox, FaStore, FaChartLine, FaTag } from "react-icons/fa";
import usaFlag from '../assets/Images/icons8-usa-48.png';
import ukFlag from '../assets/Images/icons8-great-britain-48.png';
import japanFlag from '../assets/Images/icons8-japan-48.png';
import russiaFlag from '../assets/Images/icons8-russian-federation-48.png';
import "../assets/css/Dashboard.css";
import images from "../assets/Images/cartoon-boy-pointing-with-photorealistic-renderings_899449-41198_converted.png"
import { Spinner } from "react-bootstrap";

// Sample data
const salesData = [
  { month: "Jan", sales: 4000, orders: 2400 },
  { month: "Feb", sales: 3000, orders: 1398 },
  { month: "Mar", sales: 2000, orders: 9800 },
  { month: "Apr", sales: 2780, orders: 3908 },
  { month: "May", sales: 1890, orders: 4800 },
];

const pieData = [
  { name: "Sales", value: 4000 },
  { name: "Orders", value: 2400 },
  { name: "Returns", value: 800 },
];

const barData = [
  { city: "New York", sales: 1000 },
  { city: "Los Angeles", sales: 2000 },
  { city: "Chicago", sales: 1500 },
  { city: "San Francisco", sales: 2200 },
  { city: "Miami", sales: 1700 },
];

const totalPurchases = 15000; // Example metric for total purchases
const totalSales = 20000; // Example metric for total sales
const newCustomers = 120; // Example metric for new customers
const totalOrders = 5000; // Example metric for total orders
const totalProducts = 8000; // Example metric for total products
const totalStores = 50; // Example metric for total stores
const productReturns = 500; // Example metric for product returns

// Example table data for cities with most sales
const citiesData = [
  { city: "New York", sales: 4000, flag: usaFlag },
  { city: "London", sales: 3000, flag: ukFlag },
  { city: "Tokyo", sales: 5000, flag: japanFlag },
  { city: "Moscow", sales: 2000, flag: russiaFlag },
];

const Dashboard = () => {
  const COLORS = ['#8884d8', '#82ca9d', '#ff8042'];
  const responses = [
    "The fashion industry is experiencing a rapid growth in sales. Keep up with the latest trends and continue providing exceptional products to your customers!",
    "Your latest collection is performing excellently! Stay focused on quality and customer satisfaction to drive even greater success in the coming months.",
    "Great progress this quarter! An increase in sales is visible, but don’t forget to keep monitoring returns and stock levels to ensure customer satisfaction.",
    "Your new arrivals are generating buzz in the market. Consider expanding marketing efforts to further boost visibility and engagement!",
    "Customer feedback is overwhelmingly positive! Focus on leveraging this feedback for future collections to maintain your brand’s strong reputation.",
    "With the holidays approaching, demand for your fashion products is expected to increase. Ensure smooth logistics and delivery to keep customers happy!"
  ];

  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
  const [currentResponse, setCurrentResponse] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isB2BDashboard, setIsB2BDashboard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setIsTyping(true); // Start typing animation
      setCurrentResponse(responses[currentResponseIndex]); // Show the current response
      setCurrentResponseIndex((prevIndex) => (prevIndex + 1) % responses.length); // Loop back to the first response after the last one

      setTimeout(() => {
        setIsTyping(false); // End typing animation after the response is set
      }, 3000); // Duration of the typing effect (adjust if needed)
    }, 3000); // Interval between responses (adjust if needed)
    setIsLoading(false);
    // Clean up the interval when the component unmounts
    return () => clearInterval(typingInterval);
  }, [currentResponseIndex]);

  const handleCheckboxChange = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsB2BDashboard(prevState => !prevState); // Toggle between Customer and B2B dashboard
      setIsLoading(false); // Hide spinner after a brief delay
    }, 1000);
  };

  return (
    <div className="dashboard-container">

      <div className="main-content">
        <div className="col-12">
          <div className="loding ">
            <div className="checkbox-apple" style={{ left: '90%' }}>
              <input
                className="yep"
                id="check-apple"
                type="checkbox"
                checked={isB2BDashboard}
                onChange={handleCheckboxChange}

              />
              <label htmlFor="check-apple"></label>
            </div>
            <p style={{ marginLeft: '89%', fontSize: '16px' }}>{isB2BDashboard ? "B2B Dashboard" : "Customer Dashboard"}</p>
          </div>

          {isLoading && (
            <div className="spinner-overlay">
              <div className="spinner">
                {/* Add the spinner's content here, for example, a loading animation */}
              </div>
            </div>
          )}
          <div className="chat-container">
            <div className="chat-response">
              <p className={`chat-text ${isTyping ? 'typing' : ''}`}>{currentResponse}</p>
              <img src={images} alt="" className="chat-image" />
            </div>

          </div>
        </div>

        {/* Info Cards */}
        <div className="info-cards">
          <div className="widgets card-sales">
            <div className="card-content">
              <div className="card-icon">
                <FaDollarSign size={40} />
              </div>
              <div>
                <h3>Total Sales</h3>
                <p>RS {totalSales}</p>
              </div>
            </div>
          </div>
          <div className="widgets card-purchases">
            <div className="card-content">
              <div className="card-icon">
                <FaShoppingCart size={40} />
              </div>
              <div>
                <h3>Total Purchases</h3>
                <p>RS {totalPurchases}</p>
              </div>
            </div>
          </div>
          <div className="widgets card-customers">
            <div className="card-content">
              <div className="card-icon">
                <FaUsers size={40} />
              </div>
              <div>
                <h3>New Customers</h3>
                <p>{newCustomers}</p>
              </div>
            </div>
          </div>
          <div className="widgets card-orders">
            <div className="card-content">
              <div className="card-icon">
                <FaTruck size={40} />
              </div>
              <div>
                <h3>Total Orders</h3>
                <p>{totalOrders}</p>
              </div>
            </div>
          </div>

        </div>

        {/* Graphs - Two Rows */}
        <div className="graphs-row">
          <div className="graph-box">
            <h2>Sales and Orders (Line Chart)</h2>
            <LineChart width={600} height={300} data={salesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" />
              <Line type="monotone" dataKey="orders" stroke="#82ca9d" />
            </LineChart>
          </div>

          <div className="graph-box">
            <h2>Sales and Orders Distribution (Pie Chart)</h2>
            <PieChart width={300} height={300}>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                outerRadius={120}
                fill="#8884d8"
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>

        <div className="graphs-row">
          <div className="graph-box">
            <h2>Sales by City (Bar Chart)</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={barData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="city" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="graph-box">
            <h2>Orders vs Returns</h2>
            <BarChart width={600} height={300} data={salesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sales" fill="#8884d8" />
              <Bar dataKey="orders" fill="#82ca9d" />
              <Bar dataKey="returns" fill="#ff8042" />
            </BarChart>
          </div>
        </div>

        {/* Cities Table with Flags */}
        <div className="cities-table">
          <h2>Cities with Most Sales</h2>
          <table>
            <thead>
              <tr>
                <th>City</th>
                <th>Sales</th>
                <th>Flag</th>
              </tr>
            </thead>
            <tbody>
              {citiesData.map((city, index) => (
                <tr key={index}>
                  <td>{city.city}</td>
                  <td>{city.sales}</td>
                  <td>
                    <img
                      src={city.flag} // Using imported flag image
                      alt={city.city}
                      width={40} // Adjust size as needed
                      height={40} // Adjust size as needed
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
