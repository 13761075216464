import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddFitType = () => {
  const [fitName, setFitName] = useState('');
  const [description, setDescription] = useState('');
  const [flexibility, setFlexibility] = useState('');
  const [bodyType, setBodyType] = useState('');
  const token = localStorage.getItem('responsetoken');
  const authorId = localStorage.getItem('authorid');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!fitName || !description || !flexibility || !bodyType) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Construct the object for the add request
    const newFitTypeData = {
      fitname: fitName,
      description: description,
      flexibility: flexibility,
      bodytype: bodyType,
      author_ID: authorId,
    };

    try {
      const response = await axios.post(
        'https://rollerfashions.adef.tech/api/Fit_type/Add_fittype',
        newFitTypeData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseCode === 1) {
        toast.success('Fit Type added successfully!');
        // Optionally, clear form fields or redirect
        setFitName('');
        setDescription('');
        setFlexibility('');
        setBodyType('');
      } else {
        toast.error('Failed to add Fit Type');
      }
    } catch (error) {
      console.error('Error adding Fit Type:', error);
      toast.error('An error occurred while adding Fit Type');
    }
  };

  return (
    <Container className="container-fluid addproduct addproduct-container">
      <h2 className="coupon-title mb-4">Add Fit Type</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formFitName">
              <Form.Label>Fit Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter fit name"
                value={fitName}
                onChange={(e) => setFitName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formFlexibility">
              <Form.Label>Flexibility</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter flexibility"
                value={flexibility}
                onChange={(e) => setFlexibility(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formBodyType">
              <Form.Label>Body Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter body type"
                value={bodyType}
                onChange={(e) => setBodyType(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit">
          Add Fit Type
        </Button>
      </Form>
      <ToastContainer />
    </Container>
  );
};

export default AddFitType;
