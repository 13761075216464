import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import "../assets/css/addb2bsets.css";

function EditB2BSets() {
    const { setID } = useParams(); // Get the Set ID from URL params
    const navigate = useNavigate(); // Use navigate for programmatic navigation

    const [name, setName] = useState('');
    const [productID, setProductID] = useState('');
    const [setsQuantity, setSetsQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [displayImage, setDisplayImage] = useState(null);
    const [sampleImages, setSampleImages] = useState(new Array(4).fill(null));
    const [video, setVideo] = useState(null);
    const [productOptions, setProductOptions] = useState([]); // Product list to show in dropdown
    const [loading, setLoading] = useState(true);
    const [existingData, setExistingData] = useState(null);
    const [error, setError] = useState(""); // Error state

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchProducts();
        fetchSetData();
    }, [setID]);

    // Fetch product options for the dropdown
    const fetchProducts = async () => {
        try {
            const url = 'https://rollerfashions.adef.tech/api/Product/List_Product';
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setProductOptions(response.data); // Store the product options for the dropdown
        } catch (error) {
            console.error("Error fetching products:", error);
            toast.error("Failed to fetch products", { position: "top-right", autoClose: 3000 });
        }
    };

    // Fetch existing B2B Set data based on setID
    const fetchSetData = async () => {
        try {
            const response = await axios.get(
                `https://rollerfashions.adef.tech/api/B2B_Sets/Get_B2BSets?Set_ID=${setID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            console.log(response.data);
            if (response.data) {
                setExistingData(response.data);
                setName(response.data.name);
                setProductID(response.data.product_ID);
                setSetsQuantity(response.data.sets_Quantity);
                setTotalPrice(response.data.total_Price);
                setSellingPrice(response.data.selling_price);
                setDiscount(response.data.discount);
                setDisplayImage(response.data.display_image);
                setSampleImages([
                    response.data.sample_image1,
                    response.data.sample_image2,
                    response.data.sample_image3,
                    response.data.sample_image4
                ]);
                setVideo(response.data.video);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching B2B set data:", error);
            setError("Failed to fetch B2B set data");
            toast.error("Failed to fetch B2B set data", { position: "top-right", autoClose: 3000 });
            setLoading(false);
        }
    };
    console.log("Fetched Products:", productOptions);

    const handleInputChange = (e, setter) => {
        setter(e.target.value);
    };

    const handleFileChange = (e, setter) => {
        setter(e.target.files);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 102400) { // Check if file size <= 100KB
            setDisplayImage(file);
        } else {
            toast.error("Display image size should be less than or equal to 100KB");
        }
    };

    const handleSampleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file && file.size <= 102400) { // Check if file size <= 100KB
            const updatedSampleImages = [...sampleImages];
            updatedSampleImages[index] = file;
            setSampleImages(updatedSampleImages);
        } else {
            toast.error("Image size should be less than or equal to 100KB");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const formData = new FormData();
        formData.append('id', setID);
        formData.append('name', name);
        formData.append('product_ID', productID);
        formData.append('sets_Quantity', setsQuantity);
        formData.append('total_Price', totalPrice);
        formData.append('selling_price', sellingPrice);
        formData.append('discount', discount);
        formData.append('author_ID', authorid);

        if (displayImage) {
            formData.append('display_image', displayImage);
        }

        sampleImages.forEach((sampleImage, index) => {
            if (sampleImage) {
                formData.append(`sample_image${index + 1}`, sampleImage);
            }
        });

        if (video) {
            formData.append('video', video);
        }

        try {
            const response = await axios.post(
                `https://rollerfashions.adef.tech/api/B2B_Sets/Update_B2BSets?Set_ID=${setID}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            console.log(productOptions);  // Check if it's an array of objects with product_ID and product_Name
            console.log('Selected Product ID:', productID);
            if (response.status === 1) {
                toast.success("B2B Set updated successfully!", { position: "top-right", autoClose: 3000 });
                navigate('/listsets'); // Redirect to list sets page
            }
        } catch (error) {
            setError("Failed to update B2B Set. Please try again.");
            toast.error("Failed to update B2B Set. Please try again.", { position: "top-right", autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='container-fluid addproduct addproduct-container'>
            <div className='card addproduct-card'>
                <h2 className='coupon-title'>Edit B2B Sets</h2>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Set Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => handleInputChange(e, setName)}
                                        required
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="productID">
                                        <Form.Label>Product</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={productID || ''}
                                            onChange={(e) => setProductID(e.target.value)}
                                        >
                                            <option value="">Select Product</option>
                                            {productOptions.map((product) => (
                                            console.log('Product Info',productOptions),
                                                <option key={product.product_ID} value={product.id}>
                                                    {product.name || "Unnamed Product"}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>


                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Sets Quantity</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={setsQuantity}
                                        onChange={(e) => handleInputChange(e, setSetsQuantity)}
                                        required
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Total Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={totalPrice}
                                        onChange={(e) => handleInputChange(e, setTotalPrice)}
                                        required
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={sellingPrice}
                                        onChange={(e) => handleInputChange(e, setSellingPrice)}
                                        required
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={discount}
                                        onChange={(e) => handleInputChange(e, setDiscount)}
                                        required
                                    />
                                </Col>
                            </Row>

                            {/* Display Image Preview */}
                            <Col md={12} className="mb-3">
                                <Form.Label>Display Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleImageUpload}
                                    accept="image/jpeg, image/png"
                                />
                                {displayImage && (
                                    <Card className="mt-3">
                                        <Card.Img variant="top" src={displayImage} />
                                        <Card.Body>
                                            <Card.Title>Display Image</Card.Title>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Col>

                            {/* Sample Images Preview */}
                            <Col md={12} className="mb-3">
                                <Row>
                                    {sampleImages.map((sampleImage, index) => (
                                        <Col md={3} key={index}>
                                            <Form.Group className="mb-3" controlId={`sampleImage${index + 1}`}>
                                                <Form.Label>Upload Sample Image {index + 1}</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    onChange={(e) => handleSampleImageChange(e, index)}
                                                    accept="image/jpeg, image/png"
                                                />
                                                {sampleImage && (
                                                    <Card className="mt-3">
                                                        <Card.Img variant="top" src={sampleImage} />
                                                        <Card.Body>
                                                            <Card.Title>Sample Image {index + 1}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>

                            {/* Video Upload */}
                            <Col md={12} className="mb-3">
                                <Form.Label>Video</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleFileChange(e, setVideo)}
                                    accept="video/mp4, video/webm"
                                />
                            </Col>

                            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                                {loading ? 'Updating...' : 'Update Set'}
                            </Button>
                        </Col>
                    </Row>
                )
                }
            </div >
            <ToastContainer />
        </div >
    );
}


export default EditB2BSets;
