import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

function EditAgents() {
    const { agentID } = useParams(); // Get the Agent ID from URL params
    const navigate = useNavigate(); // Use navigate for programmatic navigation

    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState(""); // Define error state

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchAgentData();
    }, [agentID]);
debugger;
    const fetchAgentData = async () => {
        try {
            const response = await axios.get(
                `https://rollerfashions.adef.tech/api/B2B_Agent/Get_B2BAgent?ID=${agentID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            console.log(response.data);
            if (response.data) {
                setUsername(response.data.username);
                setName(response.data.name);
                setPhone(response.data.phone);
                setEmail(response.data.email);
                setPassword(response.data.password);
                setProfileImage(response.data.profile_image);
            }
        } catch (error) {
            console.error("Error fetching agent data:", error);
            setError("Failed to fetch agent data");
            toast.error("Failed to fetch agent data", { position: "top-right", autoClose: 3000 });
        }
    };

    const handleInputChange = (e, setter) => {
        setter(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 102400) { // Check if file size <= 100KB
            setProfileImage(file);
        } else {
            toast.error("Profile image size should be less than or equal to 100KB");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('ID', agentID);
        formData.append('Username', username);
        formData.append('Name', name);
        formData.append('Phone', phone);
        formData.append('Email', email);
        formData.append('Password', password);
        formData.append('Author_ID', authorid);

        if (profileImage) {
            formData.append('file', profileImage);
        }

        try {
            const response = await axios.post(
                `https://rollerfashions.adef.tech/api/B2B_Agent/Update_B2BAgent`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.status === 1) {
                toast.success("Agent updated successfully!", { position: "top-right", autoClose: 3000 });
                navigate('/listagents');
            }
        } catch (error) {
            setError("Failed to update agent. Please try again.");
            toast.error("Failed to update agent. Please try again.", { position: "top-right", autoClose: 3000 });
        }
    };

    return (
        <div className="container-fluid edit-agent">
            <div className="card">
                <h2 className="coupon-title">Edit Agent</h2>
                <Row>
                    <Col md={12}>
                        {/* Form fields */}
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        onChange={(e) => handleInputChange(e, setUsername)}
                                    />
                                </Col>

                                <Col md={6} className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => handleInputChange(e, setName)}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={phone}
                                        onChange={(e) => handleInputChange(e, setPhone)}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => handleInputChange(e, setEmail)}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={(e) => handleInputChange(e, setPassword)}
                                    />
                                </Col>
                            </Row>

                            {/* Profile Image Preview */}
                            <Col md={12} className="mb-3">
                                <Form.Label>Profile Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleImageUpload}
                                    accept="image/jpeg, image/png"
                                />
                                {profileImage && (
                                    <Card className="mt-3">
                                        <Card.Img variant="top" src={URL.createObjectURL(profileImage)} />
                                        <Card.Body>
                                            <Card.Title>Profile Image</Card.Title>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Col>

                            {/* Submit Button */}
                            <Col md={12} className="mb-3">
                                <Button type="submit" variant="primary">
                                    Update Agent
                                </Button>
                            </Col>
                        </Form>
                    </Col>
                </Row>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default EditAgents;
