import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Modal, Table } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTrash } from 'react-icons/fa';
import "../assets/css/category.css";

function Category() {
    const [categoryName, setCategoryName] = useState("");
    const [categoryDiscount, setCategoryDiscount] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [isB2BApplicable, setisB2BApplicable] = useState(false);
    const [isB2CApplicable, setisB2CApplicable] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editCategory, setEditCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchCategories();
    }, [token]);

    const fetchCategories = async () => {
        try {
            debugger;
            const url = `https://rollerfashions.adef.tech/api/Category/List_Category`;


            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
            toast.error("An error occurred while fetching categories. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    const inputStyle = {
        color: 'blue',
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleCategoryDiscountChange = (e) => {
        setCategoryDiscount(e.target.value);
    };

    const handleFeaturedChange = (e) => {
        setIsFeatured(e.target.checked);
    };
    const handleB2CChange = (e) => {
        setisB2CApplicable(e.target.checked);
    };
    
    const handleB2BChange = (e) => {
        setisB2BApplicable(e.target.checked);
    };
    

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file)); // Preview the image without converting it to base64
            setImageFile(file); // Set the image file as it is
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = "https://rollerfashions.adef.tech/api/Category/Create_Category";

            if (editCategory) {
                url = `https://rollerfashions.adef.tech/api/Category/Edit_Category`;
            }

            // Create a FormData object
            const formData = new FormData();

            // Append form fields to FormData
            if (editCategory?.id) {
                formData.append('id', editCategory.id);
            }
            formData.append('name', categoryName);
            formData.append('file', imageFile); // Append the image file directly
            formData.append('isfeature', isFeatured);
            formData.append('IsB2CApplicable', isB2CApplicable);
            formData.append('IsB2BApplicable', isB2BApplicable);
            formData.append('discount', parseFloat(categoryDiscount));
            formData.append('author_ID', authorid);

            // Send the FormData with Axios
            const response = await axios.post(url, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data" // Ensure the correct content type for file uploads
                },
            });

            // Handle successful response
            if (response.status === 200) {
                toast.success("Category saved successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                setShowModal(false); // Close the modal
                setEditCategory(null); // Reset edit category state
                fetchCategories(); // Refresh categories list
            }
        } catch (error) {
            console.error("Error saving category:", error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (categoryId) => {
        try {
            const url = `https://rollerfashions.adef.tech/api/Category/Delete_Category`;
            const data = { id: categoryId, author_ID: authorid };
            await axios.post(url, data, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            toast.success("Category deleted successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            fetchCategories();
        } catch (error) {
            console.error("Error deleting category:", error);
            toast.error("An error occurred while deleting the category. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    debugger;
    const handleEdit = (category) => {
        setEditCategory(category);
        setCategoryName(category.name);
        setCategoryDiscount(category.discount.toString()); // Ensure discount is converted to string for input field
        setIsFeatured(category.isfeature);
        setImagePreview(`${category.image}`);
        setShowModal(true);
    };


    return (
        <div className="content-wrapper mt-5">
            <button
                type="button"
                className="btn btn-primary mb-3"
                onClick={() => {
                    setShowModal(true);
                    setCategoryName("");
                    setCategoryDiscount("");
                    setIsFeatured(false);
                    setImagePreview(null);
                    setEditCategory(null);
                }}
            >
                Add Category
            </button>

            {showModal && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editCategory ? "Edit Category" : "Add Category"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="pt-0" onSubmit={handleSubmit}>
                            <div className="form-floating form-floating-outline mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    style={inputStyle}
                                    id="category_name"
                                    placeholder="Enter Category Name"
                                    name="categoryTitle"
                                    aria-label="category title"
                                    value={categoryName}
                                    onChange={handleCategoryNameChange}
                                />
                                <label htmlFor="ecommerce-category-title">Category Name</label>
                            </div>
                            <div className="form-floating  form-floating-outline mb-4">
                                <input
                                    type="text"
                                    id="category_discount"
                                    style={inputStyle}
                                    className="form-control"
                                    placeholder="Enter Discount"
                                    aria-label="slug"
                                    name="slug"
                                    value={categoryDiscount}
                                    onChange={handleCategoryDiscountChange}
                                />
                                <label htmlFor="ecommerce-category-slug">Discount</label>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0 card-title">Image</h5>
                                    <a href="#" className="fw-medium" onClick={(e) => { e.preventDefault(); document.getElementById('imgfile1').click(); }}>Add Image</a>
                                </div>
                                <div className="card-body">
                                    <div className="wrapper">
                                        <div className="box">
                                            <div className="js--image-preview" id="fl_img1view" style={{ maxWidth: "200px", maxHeight: "233px", }}>
                                                {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "149px", height: "220px", }} />}
                                            </div>

                                            <div className="upload-options">
                                                <label> Upload
                                                    <input
                                                        type="file"
                                                        id="imgfile1"
                                                        className="image-upload"
                                                        accept="image/*"
                                                        onChange={handleImageChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="label_lb">Featured/Primary</label>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="Featured"
                                                checked={isFeatured}
                                                onChange={handleFeaturedChange}
                                            />
                                            <label htmlFor="Featured">Yes</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="label_lb">IsB2CApplicable</label>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="Featured"
                                                checked={isB2CApplicable}
                                                onChange={handleB2CChange}
                                            />
                                            <label htmlFor="Featured">Yes</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="label_lb">IsB2BApplicable</label>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="Featured"
                                                checked={isB2BApplicable}
                                                onChange={handleB2BChange}
                                            />
                                            <label htmlFor="Featured">Yes</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">{editCategory ? "Update" : "Add"}</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            )}
            <div className="app-ecommerce-category over">
                <div className="">
                    <div className="card-datatable table-responsive">
                        <Table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Category Name</th>
                                    <th>Discount</th>
                                    <th>Featured</th>
                                    <th>Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category) => (
                                    <tr key={category.id}>
                                        <td className="category-name-cell">
                                            <div className="category-name-wrapper">
                                                <Link to={`/subcategory/${category.id}`} className="category-name-link">
                                                    {category.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td>{category.discount}</td>
                                        <td>{category.isfeature ? 'Yes' : 'No'}</td>
                                        <td>
                                            {category.image ? (
                                                <img src={`${category.image}`} alt={category.name} style={{ width: "49px", height: "auto", objectFit: "cover" }} />
                                            ) : (
                                                <span>No Image</span>
                                            )}
                                        </td>
                                        <td>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                className="me-2"
                                                onClick={() => handleEdit(category)}
                                            >
                                                <FaEdit />
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => handleDelete(category.id)}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <ToastContainer />

        </div>
    );
}

export default Category;