import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function SetsList() {
    const [sets, setSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchSets();
    }, []);

    // Fetch the list of B2B Sets
    const fetchSets = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://rollerfashions.adef.tech/api/B2B_Sets/List_B2BSets', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setSets(response.data); // Store the sets data
        } catch (error) {
            console.error("Error fetching sets:", error);
            toast.error("Failed to fetch sets", { position: "top-right", autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    // Delete the B2B Set using FormData
    const handleDelete = async (id) => {
        try {
            const formData = new FormData();
            formData.append("ID", id);
            formData.append("Author_ID", authorid);

            setLoading(true); // Show loading indicator while deletion is in progress

            // Make the API call with FormData
            await axios.post('https://rollerfashions.adef.tech/api/B2B_Sets/Delete_B2BSets', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            // After successful deletion, show a toast notification
            toast.success("B2B Set deleted successfully", { position: "top-right", autoClose: 3000 });
            fetchSets(); // Refresh the list after deletion
        } catch (error) {
            console.error("Error deleting set:", error);
            toast.error("Failed to delete set", { position: "top-right", autoClose: 3000 });
        } finally {
            setLoading(false); // Stop loading after the deletion is complete
        }
    };

    // Render the Sets List
    return (
        <div className="container-fluid">
            <div className="card-datatable table-responsive">
                <h2 className="coupon-title">B2B Sets List</h2>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Product</th>
                                    <th>Sets Quantity</th>
                                    <th>Total Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sets.length > 0 ? (
                                    sets.map((set) => (
                                        <tr key={set.id}>
                                            <td>{set.name}</td>
                                            {/* Corrected to access the product name */}
                                            <td>{set.product ? set.product.name : 'N/A'}</td>
                                            <td>{set.sets_Quantity}</td>
                                            <td>{set.total_Price}</td>
                                            <td>
                                                <Link to={`/editsets/${set.id}`}>
                                                    <Button variant="link">
                                                        <FaEdit />
                                                    </Button>
                                                </Link>
                                                <Button
                                                    variant="link"
                                                    onClick={() => handleDelete(set.id)} // Passing the correct id
                                                >
                                                    <FaTrashAlt />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No sets found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </div>
    );
}

export default SetsList;
