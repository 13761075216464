import React, { useState, useEffect } from 'react';
import { Table, Button, Card, CardBody, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import '../../src/assets/css/productlist.css';

function ProductList() {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(10); // Number of products per page

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get("https://rollerfashions.adef.tech/api/Product/List_Product", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
            });
            setProducts(response.data);
            console.log("API Console", response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            toast.error("An error occurred while fetching products. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (productId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `https://rollerfashions.adef.tech/api/Product/Delete_Product`;
                    const obj = {
                        id: productId,
                        author_ID: authorid
                    };

                    const response = await axios.post(url, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    toast.success(response.data.responsemessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    fetchProducts();
                    Swal.fire(
                        'Deleted!',
                        'Your product has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting product:", error);
                    toast.error("An error occurred while deleting the product. Please try again.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(products.length / productsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container-fluid content-wrapper">
            <Card>

                <div className='card-datatable table-responsive'>



                    <h2 className='coupon-title'>Product Management / Product List</h2>
                    <Table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Display Image</th>
                                <th>Name</th>
                                <th>SKU Number</th>
                                <th>Price</th>
                                <th>Selling Price</th>
                                <th>Category</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentProducts && currentProducts.map(product => (
                                <tr key={product.id}>
                                    <td>
                                        <img
                                            src={`${product.display_image}`}
                                            alt={product.name}
                                            className="product-list-image"
                                            onClick={() => handleImageClick(product.display_image)}
                                        />
                                    </td>
                                    <td className="product-name">{product.name}</td>
                                    <td>{product.skq_number}</td>
                                    <td>{product.price}</td>
                                    <td>{product.selling_price}</td>
                                    <td>{product.category_Name}</td>
                                    <td>
                                        <Link to={`/editproduct/${product.id}`}>
                                            <Button variant="warning" className="me-2">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                        <Button variant="danger" className="action-button" onClick={() => handleDelete(product.id)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Pagination */}
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Button
                                key={index + 1}
                                onClick={() => paginate(index + 1)}
                                className={currentPage === index + 1 ? "active" : ""}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </div>
                </div>

            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Product Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`${selectedImage}`} alt="Product" className="img-fluid" />
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </div>
    );
}

export default ProductList;
