import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For password toggle
import { useParams, useNavigate } from 'react-router-dom'; // For routing and params
import 'bootstrap/dist/css/bootstrap.min.css';

function EditRetailer() {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [agentId, setAgentId] = useState('');
    const [agents, setAgents] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [file, setFile] = useState(null);

    const { id } = useParams(); // Get the retailer ID from URL params
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");
    const navigate = useNavigate();
debugger;
    useEffect(() => {
        const fetchRetailer = async () => {
            try {
                const response = await axios.get(`https://rollerfashions.adef.tech/api/B2B_Retailer/Get_B2BRetailer?Retailer_ID=${id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });

                const retailer = response.data; // Assuming the response contains retailer details
                setUsername(retailer.username);
                setName(retailer.name);
                setPhone(retailer.phone);
                setEmail(retailer.email);
                setPassword(retailer.password); // Set password, though it might not be returned directly.
                setAgentId(retailer.agentId);
                setImagePreview(retailer.image || null); // Assuming image URL
            } catch (error) {
                console.error("Error fetching retailer:", error);
                toast.error("Failed to fetch retailer details. Please try again.", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
            }
        };

        const fetchAgents = async () => {
            try {
                const response = await axios.get('https://rollerfashions.adef.tech/api/B2B_Agent/List_B2BAgent', {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });
                setAgents(response.data); // Assuming the response contains the list of agents
            } catch (error) {
                console.error("Error fetching agents:", error);
                toast.error("Failed to load agents. Please try again.", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
            }
        };

        fetchRetailer();
        fetchAgents();
    }, [id, token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "username") setUsername(value);
        if (name === "name") setName(value);
        if (name === "phone") setPhone(value);
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "agentId") setAgentId(value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };

        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !name || !phone || !email || !agentId) {
            toast.error("All fields are required.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('Retailer_ID', id);
        formData.append('Username', username);
        formData.append('Name', name);
        formData.append('Phone', phone);
        formData.append('Email', email);
        formData.append('Password', password); // If password is being updated
        formData.append('Agent_ID', agentId);
        if (file) formData.append('file', file);

        try {
            const response = await axios.post('https://rollerfashions.adef.tech/api/B2B_Retailer/Edit_B2BRetailer', formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            toast.success("Retailer updated successfully!", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            // Navigate to retailer list after successful update
            navigate("/retailerlist");
        } catch (error) {
            console.error("Error updating retailer:", error);
            toast.error("Failed to update retailer. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        }
    };

    return (
        <Container className="mt-5">
            <div className='card addproduct-card'>
                <h3 className="coupon-title">Edit Retailer</h3>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <div className="password-input-wrapper">
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={password}
                                        onChange={handleInputChange}
                                        placeholder="Enter your password"
                                    />
                                    <span
                                        className="password-toggle"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="file">
                                <Form.Label>Upload New File</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="file"
                                    onChange={handleFileChange}
                                />
                                {imagePreview && (
                                    <div className="image-preview mt-3">
                                        <img src={imagePreview} alt="Image Preview" width="100%" />
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="agentId">
                                <Form.Label>Agent ID</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="agentId"
                                    value={agentId}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Agent</option>
                                    {agents.map(agent => (
                                        <option key={agent.id} value={agent.id}>
                                            {agent.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type="submit" variant="primary">
                        Update Retailer
                    </Button>
                </Form>
            </div>
            <ToastContainer />
        </Container>
    );
}

export default EditRetailer;
