import React, { useState, useEffect } from 'react';
import { Table, Button, Card, CardBody, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
// import "../assets/css/fabrictypelist.css"; // Add your custom CSS here

function FabricTypeList() {
    const [fabricTypes, setFabricTypes] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchFabricTypes();
    }, []);

    const fetchFabricTypes = async () => {
        try {
            const response = await axios.get("https://rollerfashions.adef.tech/api/Fabric_Type/List_fabrictype", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
            });
            setFabricTypes(response.data);
        } catch (error) {
            console.error("Error fetching fabric types:", error);
            toast.error("An error occurred while fetching fabric types. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (fabricTypeId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `https://rollerfashions.adef.tech/api/Fabric_Type/Delete_fabrictype`;
                    const obj = {
                        id: fabricTypeId,
                        author_ID: authorid
                    };

                    const response = await axios.post(url, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    toast.success(response.data.responsemessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    fetchFabricTypes();
                    Swal.fire(
                        'Deleted!',
                        'The fabric type has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting fabric type:", error);
                    toast.error("An error occurred while deleting the fabric type. Please try again.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    };

    return (
        <div className="container-fluid content-wrapper">
            <Card>
                <CardBody>
                    <h2 className='coupon-title'>Fabric Type List</h2>
                    <Table className='table'>
                        <thead>
                            <tr>
                                <th>Fabric Name</th>
                                <th>Material Composition</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fabricTypes && fabricTypes.map(fabricType => (
                                <tr key={fabricType.id}>
                                    <td>{fabricType.fabricname}</td>
                                    <td>{fabricType.materialcomposition}</td>
                                    <td>
                                        <Link to={`/editfabrictype/${fabricType.id}`} className="btn btn-info">
                                            <FaEdit />
                                        </Link>
                                        <Button variant="danger" className="ms-2" onClick={() => handleDelete(fabricType.id)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <ToastContainer />
        </div>
    );
}

export default FabricTypeList;
