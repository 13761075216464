// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Button, Modal, Table } from "react-bootstrap";
// import { FaEdit, FaTrash } from "react-icons/fa";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "../assets/css/colormanager.css"

// function Colormanager() {
//     const [colorId, setColorId] = useState(null);
//     const [colorName, setColorName] = useState("");
//     const [colorCode, setColorCode] = useState("");
//     const [imageFile, setImageFile] = useState(null);
//     const [imagePreview, setImagePreview] = useState("");
//     const [showModal, setShowModal] = useState(false);
//     const [colors, setColors] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);

//     const token = localStorage.getItem("responsetoken");
//     const authorid = localStorage.getItem("authorid");
//     useEffect(() => {
//         fetchColors(currentPage);
//     }, [currentPage]);

//     useEffect(() => {
//         fetchColors();
//     }, []);

//     const fetchColors = async () => {
//         try {
//             const url = `https://rollerfashions.adef.tech/api/Color/List_Color`;
//             const response = await axios.get(url, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setColors(response.data);
//             setTotalPages(response.data.totalPages);
//         } catch (error) {
//             console.error("Error fetching colors:", error);
//             toast.error("Failed to fetch colors. Try again.");
//         }
//     };

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => setImagePreview(reader.result);
//             reader.readAsDataURL(file);
//             setImageFile(file);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const formData = new FormData();
//             formData.append("name", colorName);
//             formData.append("code", colorCode);
//             formData.append("author_ID", authorid);
//             if (imageFile) formData.append("file", imageFile);
//             if (colorId) formData.append("id", colorId);

//             const url = colorId
//                 ? `https://rollerfashions.adef.tech/api/Color/Edit_color`
//                 : `https://rollerfashions.adef.tech/api/Color/Add_Color`;

//             const response = await axios.post(url, formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     "Content-Type": "multipart/form-data",
//                 },
//             });

//             toast.success(response.data.responsemessage);
//             setShowModal(false);
//             fetchColors();
//         } catch (error) {
//             console.error("Error adding/editing color:", error);
//             toast.error("Error occurred. Please try again.");
//         }
//     };

//     const handleDelete = async (id) => {
//         try {
//             const url = `https://rollerfashions.adef.tech/api/Color/Delete_Color`;
//             const response = await axios.post(
//                 url,
//                 { id, author_ID: authorid },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );

//             toast.success(response.data.responsemessage);
//             fetchColors();
//         } catch (error) {
//             console.error("Error deleting color:", error);
//             toast.error("Failed to delete color.");
//         }
//     };

//     const resetForm = () => {
//         setColorId(null);
//         setColorName("");
//         setColorCode("");
//         setImageFile(null);
//         setImagePreview("");
//         setShowModal(true);
//     };
//     const handlePageChange = (newPage) => {
//         setCurrentPage(newPage);
//     };

//     return (
//         <div className="color-manager-container">
//             <Button className="color-add-btn" onClick={resetForm}>
//                 Add Color
//             </Button>

//             {/* Modal */}
//             <Modal show={showModal} onHide={() => setShowModal(false)} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>{colorId ? "Edit Color" : "Add Color"}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <form onSubmit={handleSubmit}>
//                         <input
//                             type="text"
//                             placeholder="Color Name"
//                             className="color-input"
//                             value={colorName}
//                             onChange={(e) => setColorName(e.target.value)}
//                             required
//                         />
//                         <input
//                             type="text"
//                             placeholder="Color Code"
//                             className="color-input"
//                             value={colorCode}
//                             onChange={(e) => setColorCode(e.target.value)}
//                             required
//                         />
//                         <input
//                             type="file"
//                             className="color-input"
//                             accept="image/*"
//                             onChange={handleImageChange}
//                         />
//                         {imagePreview && (
//                             <img
//                                 src={imagePreview}
//                                 alt="Preview"
//                                 className="color-image-preview"
//                             />
//                         )}
//                         <Button type="submit" className="color-btn-primary">
//                             {colorId ? "Update" : "Add"}
//                         </Button>
//                     </form>
//                 </Modal.Body>
//             </Modal>

//             <div className="card-datatable table-responsive">


//                 <Table bordered className="color-table">
//                     <thead>
//                         <tr>
//                             <th>Color Name</th>
//                             <th>Color Code</th>
//                             <th>Image</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {colors.map((color) => (
//                             <tr key={color.id}>
//                                 <td>{color.name}</td>
//                                 <td>{color.code}</td>
//                                 <td>
//                                     {color.image && (
//                                         <img
//                                             src={color.image}
//                                             alt={color.name}
//                                             className="color-image-preview"
//                                         />
//                                     )}
//                                 </td>
//                                 <td className="color-table-actions">
//                                     <Button
//                                         className="btn btn-warning"
//                                         onClick={() => {
//                                             setColorId(color.id);
//                                             setColorName(color.name);
//                                             setColorCode(color.code);
//                                             setImagePreview(color.image || "");
//                                             setShowModal(true);
//                                         }}
//                                     >
//                                         <FaEdit />
//                                     </Button>
//                                     <Button
//                                         className="btn btn-danger"
//                                         onClick={() => handleDelete(color.id)}
//                                     >
//                                         <FaTrash />
//                                     </Button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                     {/* Pagination */}
//                     <div className="pagination-controls">
//                         <Button
//                             disabled={currentPage === 1}
//                             onClick={() => handlePageChange(currentPage - 1)}
//                         >
//                             Previous
//                         </Button>
//                         <span>
//                             Page {currentPage} of {totalPages}
//                         </span>
//                         <Button
//                             disabled={currentPage === totalPages}
//                             onClick={() => handlePageChange(currentPage + 1)}
//                         >
//                             Next
//                         </Button>
//                     </div>
//                 </Table>

//                 <ToastContainer />
//             </div>
//         </div>
//     );
// }

// export default Colormanager;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/colormanager.css";

function Colormanager() {
    const [colorId, setColorId] = useState(null);
    const [colorName, setColorName] = useState("");
    const [colorCode, setColorCode] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [colors, setColors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [colorsPerPage] = useState(10); // Number of colors per page
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchColors();
    }, [currentPage]);

    const fetchColors = async () => {
        try {
            const url = `https://rollerfashions.adef.tech/api/Color/List_Color`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setColors(response.data);
            setTotalPages(Math.ceil(response.data.length / colorsPerPage));
        } catch (error) {
            console.error("Error fetching colors:", error);
            toast.error("Failed to fetch colors. Try again.");
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", colorName);
            formData.append("code", colorCode);
            formData.append("author_ID", authorid);
            if (imageFile) formData.append("file", imageFile);
            if (colorId) formData.append("id", colorId);

            const url = colorId
                ? `https://rollerfashions.adef.tech/api/Color/Edit_color`
                : `https://rollerfashions.adef.tech/api/Color/Add_Color`;

            const response = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success(response.data.responsemessage);
            setShowModal(false);
            fetchColors();
        } catch (error) {
            console.error("Error adding/editing color:", error);
            toast.error("Error occurred. Please try again.");
        }
    };

    const handleDelete = async (id) => {
        try {
            const url = `https://rollerfashions.adef.tech/api/Color/Delete_Color`;
            const response = await axios.post(
                url,
                { id, author_ID: authorid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.responsemessage);
            fetchColors();
        } catch (error) {
            console.error("Error deleting color:", error);
            toast.error("Failed to delete color.");
        }
    };

    const resetForm = () => {
        setColorId(null);
        setColorName("");
        setColorCode("");
        setImageFile(null);
        setImagePreview("");
        setShowModal(true);
    };

    // Pagination logic
    const indexOfLastColor = currentPage * colorsPerPage;
    const indexOfFirstColor = indexOfLastColor - colorsPerPage;
    const currentColors = colors.slice(indexOfFirstColor, indexOfLastColor);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="color-manager-container">
            <Button className="color-add-btn" onClick={resetForm}>
                Add Color
            </Button>

            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{colorId ? "Edit Color" : "Add Color"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Color Name"
                            className="color-input"
                            value={colorName}
                            onChange={(e) => setColorName(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Color Code"
                            className="color-input"
                            value={colorCode}
                            onChange={(e) => setColorCode(e.target.value)}
                            required
                        />
                        <input
                            type="file"
                            className="color-input"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {imagePreview && (
                            <img
                                src={imagePreview}
                                alt="Preview"
                                className="color-image-preview"
                            />
                        )}
                        <Button type="submit" className="color-btn-primary">
                            {colorId ? "Update" : "Add"}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="card-datatable table-responsive">
            <h2 className='coupon-title'>Color Management</h2>
                <Table bordered className="color-table">
                    <thead>
                        <tr>
                            <th>Color Name</th>
                            <th>Color Code</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentColors.map((color) => (
                            <tr key={color.id}>
                                <td>{color.name}</td>
                                <td>{color.code}</td>
                                <td>
                                    {color.image && (
                                        <img
                                            src={color.image}
                                            alt={color.name}
                                            className="color-image-preview"
                                        />
                                    )}
                                </td>
                                <td className="color-table-actions">
                                    <Button
                                        className="btn btn-warning"
                                        onClick={() => {
                                            setColorId(color.id);
                                            setColorName(color.name);
                                            setColorCode(color.code);
                                            setImagePreview(color.image || "");
                                            setShowModal(true);
                                        }}
                                    >
                                        <FaEdit />
                                    </Button>
                                    <Button
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(color.id)}
                                    >
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Pagination */}
                <div className="pagination-controls">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>

            </div>

            <ToastContainer />
        </div>
    );
}

export default Colormanager;
