import React, { useState, useEffect } from 'react';
import { Table, Button, Card } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

function B2BBrandList() {
    const [b2bBrands, setB2BBrands] = useState([]); // To store the list of B2B brands
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const [brandsPerPage] = useState(10); // Number of brands per page
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle errors

    const token = localStorage.getItem('responsetoken'); // Get the token from localStorage
    const authorid = localStorage.getItem('authorid'); // Get the Author ID from localStorage

    useEffect(() => {
        fetchB2BBrands(); // Fetch the list of B2B brands when the component mounts
    }, []);

    const fetchB2BBrands = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('https://rollerfashions.adef.tech/api/B2B_Brand/List_B2BBrand', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setB2BBrands(response.data); // Set the fetched data to the state
            setLoading(false); // Stop the loading state
        } catch (err) {
            setLoading(false); // Stop the loading state
            setError('Failed to fetch B2B Brands. Please try again later.'); // Set the error state
            toast.error('Failed to fetch B2B Brands. Please try again later.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        // Show confirmation dialog using SweetAlert2
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
    
        // If the user confirms the deletion
        if (result.isConfirmed) {
            try {
                // Prepare FormData
                const formData = new FormData();
                formData.append('ID', id);
                formData.append('Author_ID', authorid); // Assuming authorid is available
                
                const url = 'https://rollerfashions.adef.tech/api/B2B_Brand/Delete_B2BBrand';
                
                // Make the POST request to delete the brand
                const response = await axios.post(url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    
                    },
                });
                
                // If deletion was successful, update the state
                if (response.status === 200) {
                    setB2BBrands(b2bBrands.filter(brand => brand.id !== id)); // Remove the deleted brand from state
                    
                    toast.success('B2B Brand deleted successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    throw new Error('Failed to delete B2B Brand');
                }
            } catch (error) {
                // Handle any errors (network issues, server issues, etc.)
                toast.error('Failed to delete B2B Brand. Please try again later.', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    };
    
   
    const indexOfLastBrand = currentPage * brandsPerPage;
    const indexOfFirstBrand = indexOfLastBrand - brandsPerPage;
    const currentBrands = b2bBrands.slice(indexOfFirstBrand, indexOfLastBrand);

    const totalPages = Math.ceil(b2bBrands.length / brandsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container mt-5">
            <h3 className="coupon-title">B2B Brand List</h3>
            <Card>
                <div className="card-datatable table-responsive">
                    {/* Display loading spinner while fetching data */}
                    {loading && <div className="text-center">Loading...</div>}

                    {/* Display error message if fetching fails */}
                    {error && <div className="text-center text-danger">{error}</div>}

                    {/* If data is available, display it in a table */}
                    {!loading && !error && b2bBrands.length > 0 && (
                        <Table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Brand Name</th>
                                    <th>Brand Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentBrands.map((brand) => (
                                    <tr key={brand.id}>
                                        <td>{brand.name}</td>
                                        <td>
                                            {brand.image ? (
                                                <img
                                                    src={brand.image}
                                                    alt={brand.name}
                                                    style={{
                                                        width: '100px',
                                                        height: 'auto',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            ) : (
                                                <span>No Image</span>
                                            )}
                                        </td>
                                        <td>
                                            {/* Link to navigate to the EditB2BBrand page based on brand ID */}
                                            <Link to={`/editbrand/${brand.id}`}>
                                                <Button variant="outline-primary" size="sm" className="me-2">
                                                    <FaEdit />
                                                </Button>
                                            </Link>

                                            {/* Delete button */}
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => handleDelete(brand.id)}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                    {/* If no brands are available */}
                    {!loading && !error && b2bBrands.length === 0 && (
                        <div className="text-center">No B2B Brands available.</div>
                    )}

                    {/* Pagination */}
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Button
                                key={index + 1}
                                onClick={() => paginate(index + 1)}
                                className={currentPage === index + 1 ? "active" : ""}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </div>
                </div>
            </Card>

            {/* Toast Container for error/success notifications */}
            <ToastContainer />
        </div>
    );
}

export default B2BBrandList;
