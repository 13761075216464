import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, CardBody } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const AddFabricType = () => {
    const [fabricName, setFabricName] = useState("");
    const [materialComposition, setMaterialComposition] = useState("");
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem("authorid");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate input fields
        if (!fabricName || !materialComposition) {
            toast.error('Please fill in all required fields');
            return;
        }

        // Creating a custom model (plain JavaScript object)
        const fabricTypeData = {
            fabricname: fabricName,
            materialcomposition: materialComposition,
            author_ID: authorId,
        };

        try {
            const response = await axios.post(
                "https://rollerfashions.adef.tech/api/Fabric_Type/Add_Fabrictype",
                fabricTypeData, // Sending as JSON
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            console.log("API Response:", response.data);
            // Checking the response code for success
            if (response.data.responseCode === 1) {
                toast.success('Fabric Type added successfully!');
                // Optionally reset the form fields
                setFabricName("");
                setMaterialComposition("");
            } else {
                toast.error('Failed to add Fabric Type');
            }
        } catch (error) {
            console.error('Error adding Fabric Type:', error);
            toast.error('An error occurred while adding Fabric Type');
        }
    };

    return (
        <Container fluid className="container-fluid addproduct addproduct-container">
            <CardBody>
                <h2 className="coupon-title mb-4">Add Fabric Type</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formFabricName">
                        <Form.Label>Fabric Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter fabric name"
                            value={fabricName}
                            onChange={(e) => setFabricName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formMaterialComposition">
                        <Form.Label>Material Composition</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter material composition"
                            value={materialComposition}
                            onChange={(e) => setMaterialComposition(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Add Fabric Type
                    </Button>
                </Form>
            </CardBody>


            <ToastContainer />
        </Container>
    );
};

export default AddFabricType;
