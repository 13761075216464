import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/addbanner.css";

const AddBanner = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [top, setTop] = useState(false);
  const [middle, setMiddle] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [description, setDescription] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [isB2BApplicable, setisB2BApplicable] = useState(false);
  const [isB2CApplicable, setisB2CApplicable] = useState(false);
  const token = localStorage.getItem("responsetoken");
  const authorid = localStorage.getItem("authorid");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagePreview(null);
    }
  };
  const handleB2CChange = (e) => {
    setisB2CApplicable(e.target.checked);
  };

  const handleB2BChange = (e) => {
    setisB2BApplicable(e.target.checked);
  };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setVideo(null);
      setVideoPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!image && !video) {
      toast.error('Please upload at least one image or video.');
      return;
    }
    if (!description) {
      toast.error('Description is required.');
      return;
    }
    if (!bannerUrl) {
      toast.error('Banner URL is required.');
      return;
    }

    const formData = new FormData();
    if (image) formData.append('file', image);
    if (video) formData.append('Videofile', video);
    formData.append('top', top);
    formData.append('middle', middle);
    formData.append('bottom', bottom);
    formData.append('author_ID', authorid);
    formData.append('url', bannerUrl);
    formData.append('description', description);
    formData.append('IsB2CApplicable', isB2CApplicable);
    formData.append('IsB2BApplicable', isB2BApplicable);

    try {
      const response = await axios.post('https://rollerfashions.adef.tech/api/Banner/Create_Banner', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log("API Response:", response.data);

      if (response.data.responseCode === 1) {
        toast.success('Banner created successfully!');
        setImage(null);
        setVideo(null);
        setImagePreview(null);
        setVideoPreview(null);
      } else {
        toast.error('Failed to create banner. Response code: ' + response.data.responseCode);
      }
    } catch (error) {
      console.error('Error creating banner:', error.response ? error.response.data : error.message);
      toast.error('Error creating banner: ' + (error.response ? error.response.data.message : error.message));
    }
  };


  return (
    <Container fluid className="add-banner-container mt-5">
      <div className="card-body">
        <h2 className="coupon-title">Add Banner</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formImage">
                <Form.Label>Upload Image</Form.Label>
                <a href="#" style={{ color: 'blue' }} className="fw-medium mx-3" onClick={(e) => { e.preventDefault(); document.getElementById('imgfile1').click(); }}>Add Image</a>
                <input id="imgfile1" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                {imagePreview && (
                  <div className="preview-container">
                    <img src={imagePreview} alt="Preview" className="image-preview mt-3" />
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formVideo">
                <Form.Label>Upload Video</Form.Label>
                <a href="#" style={{ color: 'blue' }} className="fw-medium mx-3" onClick={(e) => { e.preventDefault(); document.getElementById('videofile').click(); }}>Add Video</a>
                <input id="videofile" type="file" accept="video/*" onChange={handleVideoChange} style={{ display: 'none' }} />
                {videoPreview && (
                  <div className="preview-container">
                    <video src={videoPreview} controls className="video-preview mt-3" />
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Form.Group className="mb-3" controlId="formTop">
                <Form.Check type="checkbox" label="Top" checked={top} onChange={(e) => setTop(e.target.checked)} />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3" controlId="formMiddle">
                <Form.Check type="checkbox" label="Middle" checked={middle} onChange={(e) => setMiddle(e.target.checked)} />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3" controlId="formBottom">
                <Form.Check type="checkbox" label="Bottom" checked={bottom} onChange={(e) => setBottom(e.target.checked)} />
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group className="mb-3" controlId="formB2C">
                <Form.Check type="checkbox" label="B2C" checked={isB2CApplicable} onChange={handleB2CChange} />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="mb-3" controlId="formB2B">
                <Form.Check type="checkbox" label="B2B" checked={isB2BApplicable} onChange={handleB2BChange} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="bannerUrl">
                <Form.Label>Banner URL</Form.Label>
                <Form.Control type="text" placeholder="Enter Banner URL" value={bannerUrl} onChange={(e) => setBannerUrl(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" variant="primary" className="submit-btn mt-4">Create Banner</Button>
        </Form>
      </div>
      <ToastContainer />
    </Container>
  );
};

export default AddBanner;
