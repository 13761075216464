import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, CardBody } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddHSN = () => {
    const [hsn, setHsn] = useState('');
    const [percentage, setPercentage] = useState('');
    const authorId = localStorage.getItem('authorid');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!hsn || !percentage || !authorId) {
            toast.error('Please fill in all required fields');
            return;
        }

        const hsnData = {
            hsn: hsn,
            percentage: percentage,
            author_ID: authorId,
        };

        debugger;
        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.post(
                'https://rollerfashions.adef.tech/api/Hsn/Add_Hsn', hsnData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.data.responseCode === 1) {
                toast.success('HSN created successfully!');
                setHsn('');
                setPercentage('');
            } else {
                toast.error('Failed to create HSN');
            }
        } catch (error) {
            console.error('Error creating HSN:', error);
            toast.error('An error occurred while creating HSN');
        }
    };

    return (
        <Container fluid className="container-fluid addproduct addproduct-container">
            <Row className="justify-content-md-center">
                <CardBody>


                    <Col md={12} className="form-container p-4">
                        <h2 className="text-center mb-4 ">Create HSN</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formHsn">
                                <Form.Label className="">HSN</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter HSN"
                                    value={hsn}
                                    onChange={(e) => setHsn(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPercentage">
                                <Form.Label className="">Percentage</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter percentage"
                                    value={percentage}
                                    onChange={(e) => setPercentage(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" >
                                Create HSN
                            </Button>
                        </Form>
                    </Col>
                </CardBody>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default AddHSN;
