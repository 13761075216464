import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Container, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link

function RetailerList() {
    const [retailers, setRetailers] = useState([]); // State to store retailer list
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    // Fetch retailers when the component is mounted
    useEffect(() => {
        const fetchRetailers = async () => {
            try {
                const response = await axios.get('https://rollerfashions.adef.tech/api/B2B_Retailer/List_B2BRetailer', {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });
                setRetailers(response.data); // Assuming the response contains the list of retailers
            } catch (error) {
                console.error("Error fetching retailers:", error);
                toast.error("Failed to load retailers. Please try again.", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
            }
        };

        fetchRetailers();
    }, [token]);
    debugger;
    // Handle delete retailer
    const deleteRetailer = async (id) => {
        try {
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Author_ID', authorid); // Assuming authorid is available

            // Perform the POST request with the formData
            const response = await axios.post('https://rollerfashions.adef.tech/api/B2B_Retailer/Delete_B2BRetailer', formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                toast.success("Retailer deleted successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });
                // Re-fetch retailer list after successful deletion
                setRetailers(retailers.filter(retailer => retailer.id !== id));
            }
            
        } catch (error) {
            console.error("Error deleting retailer:", error);
            toast.error("Failed to delete retailer. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        }

    };

    return (
        <Container className="mt-5">
            <div className='card-datatable table-responsive'>
                <h3 className="coupon-title">Retailer List</h3>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Agent</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {retailers.length > 0 ? (
                            retailers.map((retailer) => (
                                <tr key={retailer.id}>
                                    <td>{retailer.name}</td>
                                    <td>{retailer.username}</td>
                                    <td>{retailer.phone}</td>
                                    <td>{retailer.email}</td>
                                    <td>{retailer.agentName}</td> {/* Assuming agentName is available */}
                                    <td>
                                        {/* Edit Button with Link */}
                                        <Link to={`/editretailer/${retailer.id}`}>
                                            <Button variant="warning" size="sm" className="mr-2">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                        {/* Delete Button */}
                                        <Button variant="danger" size="sm" onClick={() => deleteRetailer(retailer.id)}>
                                            <FaTrashAlt />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">
                                    No retailers found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <ToastContainer />
        </Container>
    );
}

export default RetailerList;
