// EditHSN.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

const EditHSN = () => {
  const { id } = useParams(); // Get ID from URL parameters
  const navigate = useNavigate();
  const [hsnData, setHsnData] = useState({
    hsn: '',
    percentage: '',
    author_ID: localStorage.getItem('authorid'), 
  });
  const token = localStorage.getItem("responsetoken");

  useEffect(() => {
    const fetchHsnDetails = async () => {
      try {
        const response = await axios.get(`https://rollerfashions.adef.tech/api/Hsn/Get_Hsn?Hsn_ID=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.data) {
          setHsnData({
            hsn: response.data.hsn,
            percentage: response.data.percentage,
            author_ID: response.data.author_ID,
          });
        }
      } catch (error) {
        console.error('Error fetching HSN details:', error);
        Swal.fire('Error', 'Failed to fetch HSN details', 'error');
      }
    };

    fetchHsnDetails();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHsnData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://rollerfashions.adef.tech/api/Hsn/Edit_Hsn', {
        id: id,
        hsn: hsnData.hsn,
        percentage: hsnData.percentage,
        author_ID: hsnData.author_ID,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.responseCode === 1) {
        toast.success('HSN updated successfully!');
        navigate('/AdminDashboard/hsnlist'); // Redirect to the HSN list after successful update
      } else {
        toast.error('Failed to update HSN');
      }
    } catch (error) {
      console.error('Error updating HSN:', error);
      toast.error('An error occurred while updating HSN');
    }
  };

  return (
    <Container fluid className="edit-hsn-container">
      <Row>
        <Col md={0}>
        </Col>
        <Col md={12}>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <h2 className="text-center mb-4">Edit HSN</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formHsn">
                  <Form.Label>HSN</Form.Label>
                  <Form.Control
                    type="text"
                    name="hsn"
                    placeholder="Enter HSN"
                    value={hsnData.hsn}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPercentage">
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    name="percentage"
                    placeholder="Enter percentage"
                    value={hsnData.percentage}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Update HSN
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default EditHSN;
