import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function AddCoupon() {
    const [name, setName] = useState("");
    const [discount, setDiscount] = useState("");
    const [activatedOn, setActivatedOn] = useState("");
    const [expiredOn, setExpiredOn] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [minAmount, setMinAmount] = useState("");
    const [noOfTimeUse, setNoOfTimeUse] = useState("");
    const [title, setTitle] = useState("");

    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem("authorid");

    const clearFormFields = () => {
        setName("");
        setDiscount("");
        setActivatedOn("");
        setExpiredOn("");
        setMaxAmount("");
        setMinAmount("");
        setNoOfTimeUse("");
        setTitle("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const activatedDate = new Date(activatedOn);
            const expiredDate = new Date(expiredOn);

            if (isNaN(activatedDate.getTime()) || isNaN(expiredDate.getTime())) {
                toast.error("Invalid date format");
                return;
            }

            const couponData = {
                name,
                discount: Number(discount),
                activatedon: activatedDate.toISOString(),
                expiredon: expiredDate.toISOString(),
                max_amount: Number(maxAmount),
                min_amount: Number(minAmount),
                nooftimeuse: Number(noOfTimeUse),
                title,
                author_ID: authorId,
            };

            await axios.post('https://rollerfashions.adef.tech/api/Coupon/Add_coupon', couponData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            toast.success("Coupon added successfully");
            clearFormFields();
        } catch (error) {
            console.error("Error adding coupon:", error);
            toast.error("Failed to add coupon");
        }
    };

    return (
        <div className="container mt-5">
            <ToastContainer />
            <h2 className="mb-4 text-center">Add a New Coupon</h2>
            <Form onSubmit={handleSubmit} className="p-4 shadow rounded" style={{ backgroundColor: '#f9f9f9' }}>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Coupon Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter coupon name"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Discount (%)</Form.Label>
                            <Form.Control
                                type="number"
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                                placeholder="Enter discount percentage"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Activation Date</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={activatedOn}
                                onChange={(e) => setActivatedOn(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={expiredOn}
                                onChange={(e) => setExpiredOn(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Maximum Amount</Form.Label>
                            <Form.Control
                                type="number"
                                value={maxAmount}
                                onChange={(e) => setMaxAmount(e.target.value)}
                                placeholder="Enter max amount"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Minimum Amount</Form.Label>
                            <Form.Control
                                type="number"
                                value={minAmount}
                                onChange={(e) => setMinAmount(e.target.value)}
                                placeholder="Enter min amount"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Usage Limit</Form.Label>
                            <Form.Control
                                type="number"
                                value={noOfTimeUse}
                                onChange={(e) => setNoOfTimeUse(e.target.value)}
                                placeholder="Enter usage limit"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label>Coupon Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Enter title"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex justify-content-between">
                    <Button type="submit" variant="primary" className="px-4">
                        Submit
                    </Button>
                    <Button variant="secondary" className="px-4" onClick={clearFormFields}>
                        Clear
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default AddCoupon;
