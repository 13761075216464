import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const BannerList = () => {
  const [banners, setBanners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const token = localStorage.getItem("responsetoken");
  const authorid = localStorage.getItem("authorid");

  useEffect(() => {
    fetchBanners();
  }, []);

debugger;
  const fetchBanners = async () => {
    try {
      const response = await axios.get('https://rollerfashions.adef.tech/api/Banner/List_Banner', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      setBanners(response.data);
    } catch (error) {
      console.error('Error fetching banners', error);
      toast.error('Error fetching banners');
    }
  };

  const handleDeleteBanner = async (id) => {
    const data = {
      id: id,
      author_ID: authorid,
    };

    try {
      const response = await axios.post('https://rollerfashions.adef.tech/api/Banner/Delete_Banner', data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      toast.success('Banner deleted successfully');
      fetchBanners(); // Refresh banner list after deletion
    } catch (error) {
      console.error('Error deleting banner', error);
      toast.error('Error deleting banner');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  debugger;
  return (
    <div className="container-fluid content-wrapper">
      
      <div className='card-datatable table-responsive'>

      <h2 className='coupon-title'>Banner Management</h2>
      <Table hover variant="table table-bordered">
        <thead>
          <tr>
            <th>Image</th>
            <th>Position</th>
            <th>B2C Applicable</th>
            <th>B2B Applicable</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          
          {banners.map((banner) => (
            <tr key={banner.id}>
              <td>
                <img 
                  src={`${banner.image}`} 
                  alt="Banner" 
                  style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }} 
                  onClick={() => handleImageClick(banner.image)} 
                />
              </td>
              <td>
                {banner.top && <span className="mr-2">Top</span>}
                {banner.middle && <span className="mr-2">Middle</span>}
                {banner.bottom && <span className="">Bottom</span>}
              </td>
              <td>
                  {banner.isB2CApplicable ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  )}
                </td>
                <td>
                  {banner.isB2BApplicable ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  )}
                </td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteBanner(banner.id)}><FaTrash /></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <img src={`${selectedImage}`} alt="Preview" style={{ width: '100%' }} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      </div>
    </div>
  );
};

export default BannerList;
