import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, CardBody } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const EditFabricType = () => {
    const { fabricId } = useParams(); // Get the Fabric ID from the URL
    const [fabricName, setFabricName] = useState('');
    const [materialComposition, setMaterialComposition] = useState('');
    const token = localStorage.getItem('responsetoken');
    const authorId = localStorage.getItem('authorid');
    const navigate = useNavigate(); // For redirecting after successful update
    const [loading, setLoading] = useState(true);

    // Fetch fabric details when component mounts or fabricId changes
    useEffect(() => {
        if (fabricId) {
            fetchFabricDetails();
        }
    }, [fabricId]);

    // Function to fetch fabric details
    const fetchFabricDetails = async () => {
        try {
            console.log('Fetching details for Fabric_ID:', fabricId);

            const response = await axios.get(
                `https://rollerfashions.adef.tech/api/Fabric_Type/Get_Fabrictype?Fabric_ID=${fabricId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log('API Response:', response.data); // Log the full response

            const fabricData = response.data; // Extract the fabric data directly from response.data
            console.log('Fabric Data:', fabricData); // Log the extracted fabric data

            if (fabricData) {
                setFabricName(fabricData.fabricname || ''); // Safeguard against null values
                setMaterialComposition(fabricData.materialcomposition || ''); // Safeguard against null values
            } else {
                toast.error('No fabric data found');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching fabric details:', error);
            toast.error('Failed to fetch fabric details');
            setLoading(false);
        }
    };


    // Function to handle form submission for updating fabric type
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!fabricName || !materialComposition) {
            toast.error('Please fill in all required fields');
            return;
        }

        const updatedFabricTypeData = {
            id: fabricId,
            fabricname: fabricName,
            materialcomposition: materialComposition,
            author_ID: authorId,
        };

        try {
            const response = await axios.post(
                'https://rollerfashions.adef.tech/api/Fabric_Type/Update_Fabrictype',
                updatedFabricTypeData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log('Update Response:', response.data); // Log the update response

            if (response.data.responseCode === 1) {
                toast.success('Fabric Type updated successfully!');
                navigate('/AdminDashboard/fabrictypelist'); // Redirect after successful update
            } else {
                toast.error('Failed to update Fabric Type');
            }
        } catch (error) {
            console.error('Error updating Fabric Type:', error);
            toast.error('An error occurred while updating Fabric Type');
        }
    };

    return (
        <Container fluid className="container-fluid editfabric editfabric-container mt-5">
            <Row>
                <Col md={0}>

                </Col>

                <Col md={12}>
                    <CardBody >
                        <h2 className="coupon-title mb-4">Edit Fabric Type</h2>
                        {loading ? (
                            <p>Loading...</p> // Show a loading message or spinner while fetching data
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formFabricName">
                                    <Form.Label>Fabric Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter fabric name"
                                        value={fabricName}
                                        onChange={(e) => setFabricName(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formMaterialComposition">
                                    <Form.Label>Material Composition</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter material composition"
                                        value={materialComposition}
                                        onChange={(e) => setMaterialComposition(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Update Fabric Type
                                </Button>
                            </Form>
                        )}
                    </CardBody>

                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default EditFabricType;
