import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHome, FaBox, FaChartLine, FaUser, FaCog, FaChevronRight, FaChevronDown } from "react-icons/fa";
import logo from "../assets/Images/logo.018f8c31f8da791237c8.png"
import "../assets/css/Sidenav.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <div
      className={`sidebar ${isOpen ? "expanded" : ""}`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="logo-containers">
        <img src={logo} alt="Logo" className="logocontainers" />
      </div>
      <ul className="menu">
        <li>
          <Link to="/dashboard" className="menu-link">
            <FaHome className="menu-icon" />
            <span className="menu-text">Dashboard</span>
          </Link>
        </li>


        <li>
          <div className="menu-item" onClick={() => toggleDropdown(1)}>
            <FaBox className="menu-icon" />
            <span className="menu-text">Product Management</span>
            {dropdownOpen === 1 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 1 && (
            <ul className="dropdown">
              <li>
                <Link to="/addproduct" className="dropdown-link">
                  Add Product
                </Link>
              </li>
              <li>
                <Link to="/productlist" className="dropdown-link">
                  Product List
                </Link>
              </li>
              <li>
                <Link to="/addchildproduct" className="dropdown-link">
                  Add Child Product
                </Link>
              </li>
              <li>
                <Link to="/childproductlist" className="dropdown-link">
                  Child Product List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <Link to="/category" className="menu-link">
          <FaUser className="menu-icon" />
          <span className="menu-text">Category Management</span>
        </Link>
        <Link to="/color" className="menu-link">
          <FaUser className="menu-icon" />
          <span className="menu-text">Color Management</span>
        </Link>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(2)}>
            <FaChartLine className="menu-icon" />
            <span className="menu-text">Coupons Management</span>
            {dropdownOpen === 2 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 2 && (
            <ul className="dropdown">
              <li>
                <Link to="/addcoupons" className="dropdown-link">
                  Add Coupons
                </Link>
              </li>
              <li>
                <Link to="/listcoupons" className="dropdown-link">
                  Coupons List
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* Orders Dropdown */}
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(3)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Combo Management</span>
            {dropdownOpen === 3 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 3 && (
            <ul className="dropdown">
              <li>
                <Link to="/addcombo" className="dropdown-link">
                  Add Combo
                </Link>
              </li>
              <li>
                <Link to="/combolist" className="dropdown-link">
                  Combo's List
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* Reviews Dropdown */}
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(4)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Banner Management</span>
            {dropdownOpen === 4 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 4 && (
            <ul className="dropdown">
              <li>
                <Link to="/addbanner" className="dropdown-link">
                  Add Banner
                </Link>
              </li>
              <li>
                <Link to="/bannerlist" className="dropdown-link">
                  Banner List
                </Link>
              </li>
              <li>
                <Link to="/custombanner" className="dropdown-link">
                Add Custom Banner
                </Link>
              </li>
              <li>
                <Link to="/custombannerlist" className="dropdown-link">
               Custom Banner List
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* Payments Dropdown */}
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(5)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Fabric Type</span>
            {dropdownOpen === 5 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 5 && (
            <ul className="dropdown">
              <li>
                <Link to="/addfabrictype" className="dropdown-link">
                  Add Fabric Type
                </Link>
              </li>
              <li>
                <Link to="/fabrictypelist" className="dropdown-link">
                  Fabric Type List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(6)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Fit Type</span>
            {dropdownOpen === 6 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 6 && (
            <ul className="dropdown">
              <li>
                <Link to="/addfittype" className="dropdown-link">
                  Add Fit Type
                </Link>
              </li>
              <li>
                <Link to="/fittypelist" className="dropdown-link">
                  Fit Type List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(7)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">HSN Management</span>
            {dropdownOpen === 7 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 7 && (
            <ul className="dropdown">
              <li>
                <Link to="/addhsn" className="dropdown-link">
                  Add HSN
                </Link>
              </li>
              <li>
                <Link to="/hsnlist" className="dropdown-link">
                  HSN List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(8)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Pattern Management</span>
            {dropdownOpen === 8 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 8 && (
            <ul className="dropdown">
              <li>
                <Link to="/addpattern" className="dropdown-link">
                  Add Pattern
                </Link>
              </li>
              <li>
                <Link to="/patternlist" className="dropdown-link">
                  Pattern List
                </Link>
              </li>
            </ul>
          )}
        </li>
                <li>
          <div className="menu-item" onClick={() => toggleDropdown(9)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Occasion Management</span>
            {dropdownOpen === 9 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 9 && (
            <ul className="dropdown">
              <li>
                <Link to="/addoccasion" className="dropdown-link">
                  Add Occasion Type
                </Link>
              </li>
              <li>
                <Link to="/occasionlist" className="dropdown-link">
                  Occasion List Type
                </Link>
              </li>
            </ul>
          )}
        </li>        <li>
          <div className="menu-item" onClick={() => toggleDropdown(10)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">Collar Management</span>
            {dropdownOpen === 10 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 10 && (
            <ul className="dropdown">
              <li>
                <Link to="/addcollar" className="dropdown-link">
                  Add Collar
                </Link>
              </li>
              <li>
                <Link to="/fabrictypelist" className="dropdown-link">
                  Collar List
                </Link>
              </li>
            </ul>
          )}
        </li>
        {/* Shipping Dropdown */}
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(11)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">B2B Brand</span>
            {dropdownOpen === 11 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 11 && (
            <ul className="dropdown">
              <li>
                <Link to="/addbrand" className="dropdown-link">
                  Add Brand
                </Link>
              </li>
              <li>
                <Link to="/brandlist" className="dropdown-link">
                  Brand List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(12)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">B2B Sets</span>
            {dropdownOpen === 12 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 12 && (
            <ul className="dropdown">
              <li>
                <Link to="/addsets" className="dropdown-link">
                  Add Sets
                </Link>
              </li>
              <li>
                <Link to="/setslist" className="dropdown-link">
                  Sets List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(13)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">B2B Agent</span>
            {dropdownOpen === 13 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 13 && (
            <ul className="dropdown">
              <li>
                <Link to="/addagent" className="dropdown-link">
                  Add Agent
                </Link>
              </li>
              <li>
                <Link to="/listagent" className="dropdown-link">
                  Agent List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={() => toggleDropdown(14)}>
            <FaCog className="menu-icon" />
            <span className="menu-text">B2B Retailer</span>
            {dropdownOpen === 14 ? (
              <FaChevronDown className="dropdown-icon" />
            ) : (
              <FaChevronRight className="dropdown-icon" />
            )}
          </div>
          {dropdownOpen === 14 && (
            <ul className="dropdown">
              <li>
                <Link to="/addretailer" className="dropdown-link">
                 Add Retailer
                </Link>
              </li>
              <li>
                <Link to="/retailerlist" className="dropdown-link">
                  Retailer List
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/adminprofile" className="menu-link">
            <FaUser className="menu-icon" />
            <span className="menu-text">Profile</span>
          </Link>
        </li>
        <li>
          <Link to="/settings" className="menu-link">
            <FaCog className="menu-icon" />
            <span className="menu-text">Settings</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
