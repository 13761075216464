import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function B2BBrandForm() {
    const [name, setName] = useState('');
    const [file, setFile] = useState('');
    const authorid = localStorage.getItem("authorid");
    const token = localStorage.getItem("responsetoken");
    
    // Handle Name input change
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    // Handle File input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !file || !authorid) {
            toast.error("All fields are required", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file);
        formData.append('Author_ID', authorid);

        try {
            const response = await axios.post('https://rollerfashions.adef.tech/api/B2B_Brand/Create_B2BBrand', formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                toast.success("B2B Brand created successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                // Reset form fields after success
                setName('');
                setFile('');
            }
        } catch (error) {
            console.error("Error creating B2B brand:", error);
            toast.error("Failed to create B2B Brand. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <div className="container mt-5">
            <h3>Create B2B Brand</h3>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Brand Name</label>
                    <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="file" className="form-label">Brand Image</label>
                    <input
                        type="file"
                        id="file"
                        className="form-control"
                        onChange={handleFileChange}
                        required
                    />
                </div>


                <button type="submit" className="btn btn-primary">Create B2B Brand</button>
            </form>

            <ToastContainer />
        </div>
    );
}

export default B2BBrandForm;
