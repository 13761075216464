import React from "react";

const Reports = () => {
  return (
    <div>
      <h1>Reports</h1>
      <p>Generate detailed reports on sales, orders, and performance.</p>
      <div>
        <button>Generate Sales Report</button>
        <button>Generate Order Report</button>
        <button>Generate Performance Report</button>
      </div>
    </div>
  );
};

export default Reports;
