import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate is used for programmatic navigation
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditB2BBrand() {
    const { brandId } = useParams(); // Get the brand ID from URL params
    const navigate = useNavigate(); // Use navigate instead of history in React Router v6

    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const authorid = localStorage.getItem("authorid");
    const token = localStorage.getItem("responsetoken");

    useEffect(() => {
        // Fetch the brand data when the component mounts
        const fetchBrand = async () => {
            try {
                const response = await axios.get(
                    `https://rollerfashions.adef.tech/api/B2B_Brand/Get_B2BBrand`,
                    {
                        params: { ID: brandId }, // Pass brand ID to fetch specific brand data
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                );

                if (response.data) {
                    const { name, image } = response.data;
                    setName(name);
                    setImage(image); // Set the current image for preview
                }
                setLoading(false);
            } catch (err) {
                setError('Error fetching brand details. Please try again.');
                setLoading(false);
            }
        };

        fetchBrand();
    }, [brandId, token]);

    // Handle Name input change
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    // Handle File input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle form submission for editing
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !authorid) {
            toast.error("All fields are required", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }
        debugger;
        const formData = new FormData();
        formData.append('Name', name);
        formData.append('file', file);
        formData.append('Author_ID', authorid);
        formData.append('ID', brandId);
        try {
            const response = await axios.post(
                'https://rollerfashions.adef.tech/api/B2B_Brand/Edit_B2BBrand',
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 200) {
                toast.success("B2B Brand updated successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                // Redirect to the list page after successful update
                navigate('/brandlist'); // This is the new navigation method in React Router v6
            }
        } catch (error) {
            console.error("Error updating B2B brand:", error);
            toast.error("Failed to update B2B Brand. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <div className='container-fluid addproduct addproduct-container'>


            <div className="container mt-5">
                <h3>Edit B2B Brand</h3>

                {/* Loading state */}
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div className="text-danger">{error}</div>
                ) : (
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Brand Name</label>
                            <input
                                type="text"
                                id="name"
                                className="form-control"
                                value={name}
                                onChange={handleNameChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="file" className="form-label">Brand Image</label>
                            <input
                                type="file"
                                id="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </div>

                        {/* Display current image if available */}
                        {image && (
                            <div>
                                <img src={image} alt="Current Brand" style={{ width: '100px', height: 'auto', }} />
                            </div>
                        )}

                        <button type="submit" className="btn btn-primary mt-2">Update Brand</button>
                    </form>
                )}

                <ToastContainer />
            </div>
        </div>
    );
}

export default EditB2BBrand;
