import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, CardBody } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddPattern = () => {
    const [patternType, setPatternType] = useState('');
    const authorId = localStorage.getItem('authorid'); // Retrieve author ID from local storage

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!patternType || !authorId) {
            toast.error('Please fill in all required fields');
            return;
        }

        const patternData = {
            pattern_type: patternType,
            author_ID: authorId,
        };

        try {
            const token = localStorage.getItem("responsetoken"); // Retrieve the token
            const response = await axios.post(
                'https://rollerfashions.adef.tech/api/Pattern/Add_Pattern',
                patternData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.data.responseCode === 1) {
                toast.success('Pattern created successfully!');
                setPatternType(''); // Clear the input field
            } else {
                toast.error('Failed to create pattern');
            }
        } catch (error) {
            console.error('Error creating pattern:', error);
            toast.error('An error occurred while creating pattern');
        }
    };

    return (
        <Container fluid className="create-pattern-container mt-5">
            <CardBody>


                <Row className="justify-content-md-center">
                    <Col md={12} className="p-4">
                        <h2 className="coupon-title">Create Pattern</h2>
                        <Row>
                            <Col md={6}>

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formPatternType">
                                        <Form.Label className="">Pattern Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter pattern type"
                                            value={patternType}
                                            onChange={(e) => setPatternType(e.target.value)}
                                            required
                                            className="input-field"
                                        />
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        Create Pattern
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
            <ToastContainer />
        </Container>
    );
};

export default AddPattern;
