import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container,Row,Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const EditPattern = () => {
    const { id } = useParams(); // Get the Pattern ID from the URL
    const navigate = useNavigate();
    const [patternType, setPatternType] = useState('');
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem('authorid');

    useEffect(() => {
        const fetchPatternDetails = async () => {
            try {
                const response = await axios.get(`https://rollerfashions.adef.tech/api/Pattern/Get_Pattern?Pattern_ID=${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.data) {
                    setPatternType(response.data.pattern_type); // Assuming pattern_type exists in the response
                }
            } catch (error) {
                console.error('Error fetching pattern details:', error);
                toast.error('Failed to fetch pattern details');
            }
        };

        fetchPatternDetails();
    }, [id, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const patternData = {
            id: id,
            pattern_type: patternType,
            author_ID: authorId,
        };

        try {
            const response = await axios.post(`https://rollerfashions.adef.tech/api/Pattern/Edit_Pattern`, patternData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.responseCode === 1) {
                toast.success('Pattern updated successfully!');
                navigate('/AdminDashboard/patternlist'); // Redirect to the pattern list after successful update
            } else {
                toast.error('Failed to update pattern');
            }
        } catch (error) {
            console.error('Error updating pattern:', error);
            toast.error('An error occurred while updating the pattern');
        }
    };

    return (
        <Container fluid className="mt-5">
            <Row>
                <Col md={0}>
                
                </Col>
                <Col md={10}>
                <h2 className='coupon-title'>Edit Pattern</h2>
                <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formPatternType">
                    <Form.Label>Pattern Type</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter pattern type"
                        value={patternType}
                        onChange={(e) => setPatternType(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Update Pattern
                </Button>
            </Form>
                </Col>
            </Row>

            <ToastContainer />
        </Container>
    );
};

export default EditPattern;
