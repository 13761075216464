import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function ListAgent() {
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchAgents();
    }, []);

    // Fetch the list of B2B Agents
    const fetchAgents = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://rollerfashions.adef.tech/api/B2B_Agent/List_B2BAgent', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setAgents(response.data); // Store the agents data
        } catch (error) {
            console.error("Error fetching agents:", error);
            toast.error("Failed to fetch agents", { position: "top-right", autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    // Delete the B2B Agent
    const handleDelete = async (id) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("ID", id);
            formData.append("Author_ID", authorid);

            // Optimistic UI update: Remove the agent from the list before the server responds
            // setAgents((prevAgents) => prevAgents.filter(agent => agent.id !== id));
debugger
            // Send deletion request
            const response = await axios.post('https://rollerfashions.adef.tech/api/B2B_Agent/Delete_B2BAgent', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // After successful deletion, show a toast notification
            toast.success("B2B Agent deleted successfully", { position: "top-right", autoClose: 3000 });
        } catch (error) {
            console.error("Error deleting agent:", error);

            // Check if the error response contains a message
            const errorMessage = error.response?.data?.responsemessage || "Failed to delete agent";

            // Show the specific error message returned by the API
            toast.error(errorMessage, { position: "top-right", autoClose: 3000 });

            // If there was an error, re-fetch the agent list to restore the deleted agent
            fetchAgents();
        } finally {
            setLoading(false);
        }
    };

    // Render the Agent List
    return (
        <div className="container-fluid mt-5">
            <div className="card-datatable table-responsive">
                <h2 className="coupon-title">B2B Agent List</h2>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {agents.length > 0 ? (
                                    agents.map((agent) => (
                                        <tr key={agent.id}>
                                            <td>{agent.username}</td>
                                            <td>{agent.name}</td>
                                            <td>{agent.phone}</td>
                                            <td>{agent.email}</td>
                                            <td>
                                                <Link to={`/editagent/${agent.id}`}>
                                                    <Button variant="link">
                                                        <FaEdit />
                                                    </Button>
                                                </Link>
                                                <Button
                                                    variant="link"
                                                    onClick={() => handleDelete(agent.id)} // Pass the correct id
                                                >
                                                    <FaTrashAlt />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No agents found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ListAgent;
