import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, CardBody } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';

const PatternList = () => {
    const [patterns, setPatterns] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem('authorid');

    const fetchPatterns = async () => {
        try {
            const response = await axios.get('https://rollerfashions.adef.tech/api/Pattern/List_Pattern', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setPatterns(response.data || []);
        } catch (error) {
            console.error('Error fetching patterns:', error);
            Swal.fire('Error', 'Failed to fetch patterns', 'error');
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'This will permanently delete the pattern!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            try {
                // Correctly send the data in the body of the request
                await axios.post(`https://rollerfashions.adef.tech/api/Pattern/Delete_Pattern`, {
                    id: id,
                    author_ID: authorId,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                Swal.fire('Deleted!', 'Pattern has been deleted.', 'success');
                fetchPatterns(); // Refresh the pattern list after deletion
            } catch (error) {
                console.error('Error deleting pattern:', error);
                Swal.fire('Error', 'Failed to delete pattern', 'error');
            }
        }
    };

    useEffect(() => {
        fetchPatterns();
    }, []);

    return (
        <div className='container-fluid content-wrapper'>
            <CardBody>


                <h2 className="coupon-title">Pattern List</h2>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Pattern Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patterns.map((pattern) => (
                            <tr key={pattern.id}>
                                <td>{pattern.pattern_type}</td>
                                <td>
                                    <Link to={`/editpattern/${pattern.id}`}>
                                        <Button variant="warning" className="me-2"><FaEdit /></Button>
                                    </Link>
                                    <Button variant="danger" onClick={() => handleDelete(pattern.id)}>
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </div>
    );
};

export default PatternList;
