import React from "react";

const Orders = () => {
  return (
    <div>
      <h1>Orders</h1>
      <p>Here you can view and manage all orders.</p>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Customer</th>
            <th>Status</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>001</td>
            <td>John Doe</td>
            <td>Shipped</td>
            <td>$120.00</td>
          </tr>
          <tr>
            <td>002</td>
            <td>Jane Smith</td>
            <td>Pending</td>
            <td>$85.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
