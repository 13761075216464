import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import '../assets/css/adminlogin.css';
import logo from "../assets/Images/logo.018f8c31f8da791237c8.png";

function Admin_Login({ handleClose }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();

        const url = "https://rollerfashions.adef.tech/api/Authentication/Admin_login";
        const data = {
            username: username,
            password: password,
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const responseData = response.data;

            if (responseData.responseCode === 1) {
                toast.success(responseData.responsemessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                localStorage.setItem("responsetoken", responseData.responsetoken);
                localStorage.setItem("authorid", responseData.responseobject); // Save the admin ID

                window.location.href = "/dashboard";
            } else {
                toast.error("Login failed. Please check your credentials.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            console.error("Axios error:", error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <div className="login-container">
            <div className="row w-100">
                <div className="col-lg-4 col-md-6 col-12 d-flex flex-column justify-content-center align-items-center logo-container">
                    <img src={logo} alt="RollerFashions Logo" className="logo" />
                    <h2 className="brand-text">Welcome to RollerFashions</h2>
                    <p className="brand-description">Your ultimate destination for the latest fashion trends.</p>
                </div>

                {/* Right Column for Login Form (col-8) */}
                <div className="col-lg-8 col-md-6 col-12 d-flex justify-content-center align-items-center">
                    <div className="login-card">
                        <h3 className="login-title">Admin Login</h3>
                        <Form onSubmit={handleLogin} className="login-form">
                            <div className='logins'>
                                <Form.Group controlId="email">
                                    <Form.Label style={{marginRight:'20px'}}>Username or Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your username or email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="input-field"
                                    />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="input-field"
                                    />
                                </Form.Group>
                            </div>

                            <div className="login-button-container">
                                <Button variant="primary" type="submit" className="login-button">Login</Button>
                            </div>
                        </Form>
                        <div className="forgot-password-link">
                            <Link to="/forgotpassword">Forgot Password?</Link>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Admin_Login;
