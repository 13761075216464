import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';

const AddCustomBanner = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [comboProductIDs, setComboProductIDs] = useState([]);
  const [comboProductOptions, setComboProductOptions] = useState([]);
  const token = localStorage.getItem("responsetoken");
  const authorId = localStorage.getItem("authorid");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://rollerfashions.adef.tech/api/Product/List_Product', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setComboProductOptions(response.data.map(product => ({
        id: product.id,
        name: product.name
      })));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagePreview(null);
    }
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setVideo(null);
      setVideoPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('Imagefile', image);
    formData.append('Videofile', video);
    formData.append('Url', url);
    formData.append('Description', description);
    formData.append('Product_ids', comboProductIDs.join(',')); // Convert array to comma-separated string
    formData.append('Author_ID', authorId);

    try {
        const response = await axios.post('https://rollerfashions.adef.tech/api/Banner/Create_custombanner', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data);
        if (response.data.responseCode == 1) {
            toast.success('Custom banner created successfully!');
            // Reset the form
            setImage(null);
            setVideo(null);
            setImagePreview(null);
            setVideoPreview(null);
            setUrl("");
            setDescription("");
            setComboProductIDs([]);
        } else {
            toast.error('Failed to create custom banner. Please try again.');
        }

    } catch (error) {
        console.error('Error creating custom banner:', error);
        toast.error('Error creating custom banner');
    }
};


  return (
    <Container fluid className="add-banner-container mt-5">
      <div className="card-body">
      <h2 className="coupon-title">Add Custom Banner</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formImage">
                <Form.Label>Upload Image</Form.Label>
                <a href="#"  style={{color:'blue'}} className="fw-medium mx-3" onClick={(e) => { e.preventDefault(); document.getElementById('imgfile1').click(); }}>Add Image</a>
                <input id="imgfile1" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                {imagePreview && (
                  <div className="preview-container">
                    <img src={imagePreview} alt="Preview" className="image-preview mt-3" />
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formVideo">
                <Form.Label>Upload Video</Form.Label>
                <a href="#" style={{color:'blue'}} className="fw-medium mx-3" onClick={(e) => { e.preventDefault(); document.getElementById('videofile').click(); }}>Add Video</a>
                <input id="videofile" type="file" accept="video/*" onChange={handleVideoChange} style={{ display: 'none' }} />
                {videoPreview && (
                  <div className="preview-container">
                    <video src={videoPreview} controls className="video-preview mt-3" />
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="comboProductIDs">
                <Form.Label>Products ID's</Form.Label>
                <Multiselect
                  options={comboProductOptions}
                  selectedValues={comboProductOptions.filter(option => comboProductIDs.includes(option.id))}
                  onSelect={(selectedList) => {
                    setComboProductIDs(selectedList.map(item => item.id));
                  }}
                  onRemove={(selectedList) => {
                    setComboProductIDs(selectedList.map(item => item.id));
                  }}
                  displayValue="name"
                  placeholder="Select Products ID's"
                  showCheckbox
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="url">
                <Form.Label>URL</Form.Label>
                <Form.Control type="text" placeholder="Enter URL" value={url} onChange={(e) => setUrl(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" variant="primary" className="submit-btn mt-4">Create Custom Banner</Button>
        </Form>
      </div>
      <ToastContainer />
    </Container>
  );
};

export default AddCustomBanner;
