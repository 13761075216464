import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

function ListCoupons() {
    const [coupons, setCoupons] = useState([]);
    const token = localStorage.getItem("responsetoken");

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = async () => {
        try {
            const response = await axios.get(`https://rollerfashions.adef.tech/api/Coupon/List_coupon?Token=${token}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCoupons(response.data);
        } catch (error) {
            console.error("Error fetching coupons:", error);
            toast.error('Failed to fetch coupons');
        }
    };

    const handleDelete = async (couponId) => {
        if (window.confirm("Are you sure you want to delete this coupon?")) {
            try {
                await axios.delete(`https://rollerfashions.adef.tech/api/Coupon/Delete_coupon?id=${couponId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                toast.success("Coupon deleted successfully");
                fetchCoupons(); // Re-fetch coupon list after deletion
            } catch (error) {
                console.error("Error deleting coupon:", error);
                toast.error("Failed to delete coupon");
            }
        }
    };

    return (
        <div className="container mt-5">
            <ToastContainer />
            
            <div className='card-datatable table-responsive'>
            <h2 className="mb-4 text-center">Coupon List</h2>
           
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Discount (%)</th>
                        <th>Activation Date</th>
                        <th>Expiry Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {coupons.map((coupon) => (
                        <tr key={coupon.id}>
                            <td>{coupon.name}</td>
                            <td>{coupon.discount}</td>
                            <td>{coupon.activatedon}</td>
                            <td>{coupon.expiredon}</td>
                            <td>
                                <Link to={`/editcoupons/${coupon.id}`}>
                                    <Button variant="warning"  className="me-2">
                                       <FaEdit />
                                    </Button>
                                </Link>
                                <Button variant="danger"  onClick={() => handleDelete(coupon.id)}>
                                    <FaTrash />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
        </div>
    );
}

export default ListCoupons;
